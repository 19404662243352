import { TABLENAMES } from "../payment.config";
import { CHANGE_ACTIVE_TAB, LOADER, RESET_FILTERS, RETURN_TO_LANDING_PAGE, SELECTED_ROW, UPDATE_FILTER_DATA, UPDATE_MULTIPLE_DATA } from "./actions";

export const initialState = {
    loading: false,
    activeTab: TABLENAMES.notMergedTable,
    tableData: [],
    showLandingPage: true,
    selectedRowData: {},
    paymentInvoiceDetailTable: { related_payments: [], related_invoices: [] },
}

export const initialNotMergedTableFilters = {
    clientOptions: [],
    yearOptions: [],
    currencyOptions: [],

    client_name: [],
    currency_code: [],

    client_id: [],
    reporting_year: "",
    currency_id: [],
}

export const initialMergedTableFilters = {
    clientOptions: [],
    currencyOptions: [],

    client_name: [],
    currency_code: [],

    client_id: [],
    currency_id: [],
}


export const paymentDataReducer = (state, action) => {
    switch (action.type) {
        case LOADER:
            return { ...state, loading: action.payload };
        case UPDATE_MULTIPLE_DATA:
            return { ...state, ...action.payload };
        case RETURN_TO_LANDING_PAGE:
            return { ...state, selectedRowData: {}, showLandingPage: true };
        case CHANGE_ACTIVE_TAB:
            return { ...state, activeTab: action.payload };
        case SELECTED_ROW:
            return {
                ...state,
                selectedRowData: action.payload,
                showLandingPage: false,
            };
        case RESET_FILTERS:
            return {
                ...state,
                tableData: [],
            };
        default:
            return state;
    }
};

export const notMergedTableFiltersDataReducer = (state, action) => {
    switch (action.type) {
        case UPDATE_FILTER_DATA:
            return { ...state, ...action.payload };
        case RESET_FILTERS:
            return {
                ...state,
                client_name: [],
                year_name: [],
                currency_code: [],
                client_id: [],
                reporting_year: "",
                currency_id: [],
            };
        default:
            return state;
    }
};

export const mergedTableFiltersDataReducer = (state, action) => {
    switch (action.type) {
        case UPDATE_FILTER_DATA:
            return { ...state, ...action.payload };
        case RESET_FILTERS:
            return {
                ...state,
                client_name: [],
                currency_code: [],
                client_id: [],
                currency_id: [],
            };
        default:
            return state;
    }
};

