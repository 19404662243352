import React, { useContext } from 'react';
import { View, Text } from 'react-native';
import { ActionsButtonIcons, CommonDataTable } from '../../../components';
import { ACTION_BUTTON_TYPE } from '../../../constants/GlobalVariables';
import { breadCrumbStyle } from '../../../components/BreadCrumb/breadCrumbStyle';
import { useTheme } from 'react-native-paper';
import InvoicesDataContextAPI from './InvoicesDataContextAPI';
import { config } from './invoice.config';
import PaymentContextAPI from '../Payments/Reducer/PaymentsContextAPI';
import { formatCurrency } from '../../../utils/formatCurrency';
import dayjs from "dayjs";
import { useEffect } from 'react';
import { UPDATE_MULTIPLE_DATA } from '../Payments/Reducer/actions';
import { TABLENAMES } from '../Payments/payment.config';

const InvoicePaymentDetailsTable = ({ pageName }) => {
    let theme = useTheme();
    let {
        state: { invoicePaymentDetailTable },
    } = useContext(InvoicesDataContextAPI);

    let {
        state: { activeTab },
        getPaymentByID, dispatch: paymentDispatch
    } = useContext(PaymentContextAPI);

    useEffect(() => {
        let isMouled = true
        if (isMouled && invoicePaymentDetailTable?.data && invoicePaymentDetailTable?.data.length > 0) {
            paymentDispatch({ type: UPDATE_MULTIPLE_DATA, payload: { activeTab: invoicePaymentDetailTable?.merged ? TABLENAMES.mergedTable : TABLENAMES.notMergedTable } })
        }
        return () => {
            isMouled = false
        };
    }, [invoicePaymentDetailTable]);

    let columns = [
        {
            key: "payment_id",
            title: "ID",
            dataIndex: "payment_id",
            sort: true,
            search: true,
            width: 1,
            // bold: true,
        },
        {
            key: "client_id",
            title: "Client ID",
            dataIndex: "client_id",
            sort: true,
            search: true,
            filter: true,
            width: 1.5,
        },
        {
            key: "client_name",
            title: "Client Name",
            dataIndex: "client_name",
            sort: true,
            search: true,
            width: 2.5,
        },
        {
            key: "currency",
            title: "Currency",
            dataIndex: "currency",
            sort: true,
            filter: true,
            width: 1.6,
        },
        {
            key: "payment_currency",
            title: "Payment Currency",
            dataIndex: "payment_currency",
            sort: true,
            filter: true,
            width: 2.8,
        },
        {
            key: "date_requested",
            title: "Requested",
            dataIndex: "date_requested",
            sort: true,
            filter: true,
            width: 2,
            isDate: true,
            customToolTip: (date) => {
                return date ? dayjs(date).format("DD/MM/YYYY") : null
            },
            render: (date) => {
                return date ? dayjs(date).format("DD/MM/YYYY") : null
            },
        },
        {
            key: "remittance_date",
            title: "Date Paid",
            dataIndex: "remittance_date",
            sort: true,
            filter: true,
            width: 2,
            isDate: true,
            customToolTip: (date) => {
                return date ? dayjs(date).format("DD/MM/YYYY") : null
            },
            render: (date) => {
                return date ? dayjs(date).format("DD/MM/YYYY") : null
            },
        },
        {
            key: "amountsubmitted",
            title: "Amount",
            dataIndex: "amountsubmitted",
            sort: true,
            width: 2,
            render: (currency) => {
                return formatCurrency(currency);
            },
        },
        {
            key: "type",
            title: "Type",
            dataIndex: "type",
            sort: true,
            filter: true,
            width: 4,
        },
        {
            key: "paid_via",
            title: "Paid Via",
            dataIndex: "paid_via",
            sort: true,
            filter: true,
            width: 2.2,
        },
        {
            key: "no_of_invoices",
            title: "# Invoices",
            dataIndex: "no_of_invoices",
            sort: true,
            filter: true,
            width: 1.8,
        },
        {
            key: "status",
            title: "Status",
            dataIndex: "status",
            sort: true,
            filter: true,
            width: 1.4,
        },
        {
            key: "approved_by",
            title: "Approved",
            dataIndex: "approved_by",
            sort: true,
            width: 1.4,
        },
    ];

    let invoiceColumns = [...columns, {
        key: "payment_id",
        title: "View",
        dataIndex: "payment_id",
        center: true,
        // hideToolTip: true,
        width: 0.8,
        customToolTip: () => {
            return "View payment detail";
        },
        render: (id) => {
            return (
                <ActionsButtonIcons
                    buttonType={ACTION_BUTTON_TYPE.FILE_SEARCH}
                    handleOnPress={() => {
                        getPaymentByID(id);
                    }}
                />
            );
        },
    }]

    return (
        <View style={{ marginTop: 36, gap: 15 }}>
            <Text style={breadCrumbStyle(theme).titleLink}>Payment Details {`(${invoicePaymentDetailTable?.data?.length}) ${invoicePaymentDetailTable?.data?.length > 0 ? "- " + activeTab : ""}`}</Text>
            <CommonDataTable
                canFilterColumns
                columns={pageName === config.TITLE ? invoiceColumns : columns}
                data={invoicePaymentDetailTable?.data || []}
                tableMaxHeight={700}
            />
        </View>
    );
}

export default InvoicePaymentDetailsTable;
