import React from "react";
import { Text, View } from "react-native";
import { Modal, Portal, withTheme } from "react-native-paper";
import { AntDesign } from "@expo/vector-icons";
import { customModalStyle } from "./customModalStyle";
import { AuthProvider } from "../../context/AuthContextAPI";

const CustomModal = ({
  title = "",
  visible,
  hideModal,
  theme,
  children,
  customTitle = <></>,
}) => {
  return (
    <Portal>
      <AuthProvider>
        <Modal
          style={customModalStyle(theme).modalWrapper}
          dismissable={false}
          visible={visible}
          onDismiss={hideModal}
          contentContainerStyle={customModalStyle(theme).container}
          theme={{
          colors: {
          backdrop: 'transparent',
    },
  }}
        >
          <View style={customModalStyle(theme).bodyContainer}>
            <View>
              {customTitle && customTitle}
              {title ? (
                <Text style={customModalStyle(theme).title}>{title}</Text>
              ) : (
                <></>
              )}
            </View>
            <AntDesign
              onPress={hideModal}
              name="close"
              size={24}
              style={customModalStyle(theme).closeIcon}
            />
          </View>
          <View style={customModalStyle(theme).bodyContent}>{children}</View>
        </Modal>
      </AuthProvider>
    </Portal>
  );
};

export default withTheme(CustomModal);
