import React from 'react'
import { MergePaymentsProvider } from './Reducer/MergePaymentsContextAPI'
import MergePayments from './MergePayments'

const MergePaymentsConsumer = () => {
    return (
        <MergePaymentsProvider>
          <MergePayments/>
        </MergePaymentsProvider>
    )
}

export default MergePaymentsConsumer