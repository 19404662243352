import { LOADER, RESET_FILTERS, UPDATE_MULTIPLE_DATA, REFRESH_TABLE } from "./actions";

export const initialState = {
    loading: false,
    refreshTable: 0,
    tableData: [],

    clientOptions: [],
    yearOptions: [],
    currencyOptions: [],

    client_name: [],
    currency_code: [],

    client_id: [],
    reporting_year: "",
    currency_id: [],
}

export const ImportPaymentDataReducer = (state, action) => {
    switch (action.type) {
        case LOADER:
            return { ...state, loading: action.payload };
        case UPDATE_MULTIPLE_DATA:
            return { ...state, ...action.payload };
        case RESET_FILTERS:
            return {
                ...state,
                client_name: [],
                client_id: [],
                year_name: [],
                currency_code: [],
                reporting_year: "",
                currency_id: [],
                tableData: [],
            };
        case REFRESH_TABLE:
            return { ...state, refreshTable: state.refreshTable + 1 };    
        default:
            return state;
    }
};
