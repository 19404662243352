import React, { useContext } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { breadCrumbStyle } from '../../../components/BreadCrumb/breadCrumbStyle';
import { useTheme } from 'react-native-paper';
import PaymentContextAPI from './Reducer/PaymentsContextAPI';
import { CommonDataTable } from '../../../components';
import { formatCurrency } from '../../../utils/formatCurrency';
import dayjs from "dayjs";


const RelatedPaymentsTable = () => {
    let theme = useTheme();
    let {
        state: { paymentInvoiceDetailTable },
    } = useContext(PaymentContextAPI);

    let columns = [
        {
            key: "paymentid",
            title: "ID",
            dataIndex: "paymentid",
            sort: true,
            search: true,
            width: 0.8,
            // bold: true,
        },
        {
            key: "client_id",
            title: "Client ID",
            dataIndex: "client_id",
            sort: true,
            search: true,
            width: 1.2,
        },
        {
            key: "client_name",
            title: "Client Name",
            dataIndex: "client_name",
            sort: true,
            search: true,
            width: 2.5,
        },
        {
            key: "currency",
            title: "Currency",
            dataIndex: "currency",
            sort: true,
            width: 1.3,
        },
        {
            key: "paymentcurrency",
            title: "Payment Currency",
            dataIndex: "paymentcurrency",
            sort: true,
            width: 2.3,
        },
        {
            key: "date_requested",
            title: "Requested",
            dataIndex: "date_requested",
            sort: true,
            filter: true,
            width: 1.5,
            isDate: true,
            customToolTip: (date) => {
                return date ? dayjs(date).format("DD/MM/YYYY") : null
            },
            render: (date) => {
                return date ? dayjs(date).format("DD/MM/YYYY") : null
            },
        },
        {
            key: "remittance_date",
            title: "Remitted",
            dataIndex: "remittance_date",
            sort: true,
            filter: true,
            width: 1.5,
            isDate: true,
            customToolTip: (date) => {
                return date ? dayjs(date).format("DD/MM/YYYY") : null
            },
            render: (date) => {
                return date ? dayjs(date).format("DD/MM/YYYY") : null
            },
        },
        {
            key: "amountsubmitted",
            title: "Amount",
            dataIndex: "amountsubmitted",
            sort: true,
            width: 2,
            total: true,
            render: (currency) => {
                return formatCurrency(currency);
            },
        },
        {
            key: "type",
            title: "Type",
            dataIndex: "type",
            sort: true,
            filter: true,
            width: 4,
        },
        {
            key: "paid_via",
            title: "Paid Via",
            dataIndex: "paid_via",
            sort: true,
            width: 2,
        },
        {
            key: "no_of_invoices",
            title: "# Invoices",
            dataIndex: "no_of_invoices",
            sort: true,
            width: 1.5,
        },
        {
            key: "status",
            title: "Status",
            dataIndex: "status",
            sort: true,
            width: 1.2,
        },
        {
            key: "approved_by",
            title: "Approved",
            dataIndex: "approved_by",
            sort: true,
            width: 1.4,
        },
    ]


    return (
        <View style={styles(theme).container}>
            <Text style={[breadCrumbStyle(theme).titleLink, styles(theme).titleTextMargin]}>{`Related Payments (${paymentInvoiceDetailTable?.related_payments.length})`}</Text>
            <CommonDataTable
                canFilterColumns
                showFooterTotal
                showPagination={false}
                columns={columns}
                data={paymentInvoiceDetailTable?.related_payments}
            />
        </View>
    );
}

export default RelatedPaymentsTable;

export const styles = (theme) =>
    StyleSheet.create({
        container: { marginVertical: 15 },
        titleTextMargin: { marginBottom: 15 },
    });
