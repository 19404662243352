import { StyleSheet, View } from "react-native";
import React, { useContext, useEffect, useState } from "react";
import { LOADER, UPDATE_SHOW_LANDING_PAGE } from "./Reducer/actions";
import ReportsContextAPI from "./ReportsContextAPI";
import {
  CustomButton,
  WebContentContainerWrapper,
  commonStatisButtonCustomStyle,
} from "../../../components";
import { REPORT_STATUS, config } from "./report.config";
import useAxios from "../../../hooks/useAxios";
import axios from "axios";
import { useTheme } from "react-native-paper";
import {
  DEFAULT_ERROR_MSG,
  DOWNLOAD_CSV_TEXT,
  DOWNLOAD_XLS_TEXT,
} from "../../../constants/GlobalVariables";
import ShowAllSelectFilters from "./ShowAllSelectFilters";
import { REPORTS_URL } from "../../../constants/GlobalUrls";
import { downloadFile } from "./downloadFileUsingURL";
import AuthContextAPI from "../../../context/AuthContextAPI";
import { checkNetworkReachable } from "../../../utils/checkNetworkReachable";

const IndividualReportPreview = () => {
  // api hook
  let api = useAxios();

  const { logoutUser } = useContext(AuthContextAPI);

  // cancel token used to cancel the call if component is unmounted or need to programmatically cancel the request
  const cancelTokenSource = axios.CancelToken.source();
  let theme = useTheme();
  let {
    state: { tablePreviewRow, loading },
    dispatch,
  } = useContext(ReportsContextAPI);

  const [selectedFilters, setSelectedFilters] = useState({});

  useEffect(() => {
    let isMoulded = true;
    if (isMoulded) {
      getSelectedFilters();
    }

    return () => {
      isMoulded = false;
    };
  }, []);

  const getSelectedFilters = async () => {
    dispatch({ type: LOADER, payload: true });
    const handleSuccessResponse = (res) => {
      setSelectedFilters(res);
    };
    let url = `${REPORTS_URL}report_filters/${tablePreviewRow.id}`;
    await api
      .get(url, {
        cancelToken: cancelTokenSource.token,
      })
      .then((res) => {
        handleSuccessResponse(res.data);
      })
      .catch((error) => {
        checkNetworkReachable();
        if (error?.response?.status === 401) {
          logoutUser();
        } else if (!axios.isCancel(error) && error?.response) {
          alert(DEFAULT_ERROR_MSG);
        }
      });
    dispatch({ type: LOADER, payload: false });
  };

  const goToLandingPage = () => {
    dispatch({
      type: UPDATE_SHOW_LANDING_PAGE,
    });
  };

  const breadCrumbData = [
    {
      title: config.TITLE,
      onClickPress: goToLandingPage,
    },
    {
      title: tablePreviewRow?.filename,
      active: true,
    },
  ];

  return (
    <WebContentContainerWrapper
      breadCrumbData={breadCrumbData}
      loading={loading}
    >
      <ShowAllSelectFilters data={selectedFilters} />
      <View style={styles.btnContainer}>
        <CustomButton
          disabled={
            !(
              selectedFilters.status &&
              selectedFilters.status === REPORT_STATUS.COMPLETED
            )
          }
          text={DOWNLOAD_CSV_TEXT}
          textStyle={commonStatisButtonCustomStyle(theme).text}
          onPress={() => {
            downloadFile(tablePreviewRow?.id, selectedFilters.csvurl);
          }}
        />
        <CustomButton
          disabled={
            !(
              selectedFilters.status &&
              selectedFilters.status === REPORT_STATUS.COMPLETED
            )
          }
          text={DOWNLOAD_XLS_TEXT}
          cutomButtonStyle={commonStatisButtonCustomStyle(theme).downloadBtn}
          textStyle={commonStatisButtonCustomStyle(theme).text}
          onPress={() => {
            downloadFile(tablePreviewRow?.id, selectedFilters.xlsurl);
          }}
        />
      </View>
    </WebContentContainerWrapper>
  );
};

export default IndividualReportPreview;

const styles = StyleSheet.create({
  searchGroupByContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  btnContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
});
