import { View } from "react-native";
import React, { useCallback, useContext, useState } from "react";
import { Searchbar, useTheme } from "react-native-paper";
import { commonstyle } from "../Accounts/AccountEditPage/commonstyle";
import {
  ActionsButtonIcons,
  CommonDataTable,
  commonStatisticButtonStyle,
  ConfirmationWindow,
  CustomModal,
} from "../../../components";
import { ACTION_BUTTON_TYPE, DEFAULT_ERROR_MSG } from "../../../constants/GlobalVariables";
import PaymentContextAPI from "./Reducer/PaymentsContextAPI";
import { config, TABLENAMES } from "./payment.config";
import { LOADER, SELECTED_ROW } from "./Reducer/actions";
import { formatCurrency } from "../../../utils/formatCurrency";
import dayjs from "dayjs";
import { checkNetworkReachable } from "../../../utils/checkNetworkReachable";
import axios from "axios";
import useAxios from "../../../hooks/useAxios";
import AuthContextAPI from "../../../context/AuthContextAPI";
import { GET_MERGED_PAYMENTS_URL } from "../../../constants/GlobalUrls";

const PaymentTable = ({ activeTab, selectActiveTab }) => {
  // api hook
  let api = useAxios();

  const { logoutUser } = useContext(AuthContextAPI);

  let theme = useTheme();
  // cancel token used to cancel the call if component is unmounted or need to programmatically cancel the request
  const cancelTokenSource = axios.CancelToken.source();
  let {
    state: { tableData },
    dispatch,
    getData,
  } = useContext(PaymentContextAPI);

  const [searchQuery, setSearchQuery] = useState("");
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState({
    show: false,
    record: {},
  });
  const onChangeSearch = (query) => setSearchQuery(query);

  const closeDeleteConfirmationWindow = () => {
    setShowDeleteConfirmation({ ...showDeleteConfirmation, show: false });
  };

  const onClickViewDetailPage = (record) => {
    dispatch({ type: SELECTED_ROW, payload: record });
  };

  const openDeleteConfirmationWindow = (rowData) => {
    setShowDeleteConfirmation({ show: true, record: rowData });
  };

  const handleDelete = async () => {
    dispatch({ type: LOADER, payload: true });

    let id = showDeleteConfirmation?.record?.id;
    let url = `${GET_MERGED_PAYMENTS_URL}${id}`;
    closeDeleteConfirmationWindow();

    const handleSuccessResponse = (res) => {
      if (res?.msg) {
        alert(res.msg);
      }
      getData()
    };

    const handleErrorResponse = (error) => {
      checkNetworkReachable();
      if (error?.response?.status === 401) {
        logoutUser();
      } else if (!axios.isCancel(error) && error?.response) {
        alert(DEFAULT_ERROR_MSG);
      }
    };

    await api
      .delete(url, {
        cancelToken: cancelTokenSource.token,
      })
      .then((res) => {
        handleSuccessResponse(res.data);
      })
      .catch((error) => {
        handleErrorResponse(error);
        dispatch({ type: LOADER, payload: false });
      });
  };

  let getColumns = useCallback(
    () => {
      let columns = [
        {
          key: "id",
          title: "ID",
          dataIndex: "id",
          sort: true,
          search: true,
          width: 1,
          // bold: true,
        },
        {
          key: "client_id",
          title: "Client ID",
          dataIndex: "client_id",
          sort: true,
          search: true,
          filter: true,
          width: 1.5,
        },
        {
          key: "client_name",
          title: "Client Name",
          dataIndex: "client_name",
          sort: true,
          search: true,
          filter: true,
          width: 2.5,
        },
        {
          key: "currency",
          title: "Currency",
          dataIndex: "currency",
          sort: true,
          filter: true,
          width: 1.5,
        },
        {
          key: "payment_currency",
          title: "Payment Currency",
          dataIndex: "payment_currency",
          sort: true,
          filter: true,
          width: 2.6,
        },
        {
          key: "date_requested",
          title: "Requested",
          dataIndex: "date_requested",
          sort: true,
          filter: true,
          width: 2,
          isDate: true,
          customToolTip: (date) => {
            return date ? dayjs(date).format("DD/MM/YYYY") : null
          },
          render: (date) => {
            return date ? dayjs(date).format("DD/MM/YYYY") : null
          },
        },
        {
          key: "remittance_date",
          title: "Remitted",
          dataIndex: "remittance_date",
          sort: true,
          filter: true,
          width: 2,
          isDate: true,
          customToolTip: (date) => {
            return date ? dayjs(date).format("DD/MM/YYYY") : null
          },
          render: (date) => {
            return date ? dayjs(date).format("DD/MM/YYYY") : null
          },
        },
        {
          key: "amountsubmitted",
          title: "Amount",
          dataIndex: "amountsubmitted",
          sort: true,
          width: 2,
          render: (currency) => {
            return formatCurrency(currency);
          },
        },
        {
          key: "type",
          title: "Type",
          dataIndex: "type",
          sort: true,
          filter: true,
          width: 4,
        },
        {
          key: "paid_via",
          title: "Paid Via",
          dataIndex: "paid_via",
          sort: true,
          filter: true,
          width: 2.2,
        },
        {
          key: "no_of_invoices",
          title: "# Invoices",
          dataIndex: "no_of_invoices",
          sort: true,
          filter: true,
          width: 1.8,
        },
        {
          key: "status",
          title: "Status",
          dataIndex: "status",
          sort: true,
          filter: true,
          width: 1.5,
        },
        {
          key: "approved_by",
          title: "Approved",
          dataIndex: "approved_by",
          sort: true,
          filter: true,
          width: 1.6,
        },
        {
          key: "View",
          title: "View",
          dataIndex: "View",
          center: true,
          // hideToolTip: true,
          width: 0.8,
          customToolTip: () => {
            return "View more details";
          },
          render: (id, record) => {
            return (
              <ActionsButtonIcons
                buttonType={ACTION_BUTTON_TYPE.FILE_SEARCH}
                handleOnPress={() => {
                  onClickViewDetailPage(record);
                }}
              />
            );
          },
        },
      ]
      if (activeTab === TABLENAMES.notMergedTable) {
        return columns
      }
      return [...columns, {
        key: ACTION_BUTTON_TYPE.DELETE,
        title: "Delete",
        dataIndex: "id",
        width: 1,
        center: true,
        hideToolTip: true,
        render: (id, record) => {
          return (
            <ActionsButtonIcons
              buttonType={ACTION_BUTTON_TYPE.DELETE}
              handleOnPress={() => {
                openDeleteConfirmationWindow(record);
              }}
            />
          );
        },
      },];
    },
    [activeTab],
  )


  return (
    <View>
      <View style={commonstyle(theme).searchTableWrapper}>
        <Searchbar
          placeholder={config.TABLE_SEARCH_PLACEHOLDER}
          onChangeText={onChangeSearch}
          value={searchQuery}
          style={commonStatisticButtonStyle(theme, 700).searchBar}
          inputStyle={commonStatisticButtonStyle(theme).searchBarInput}
        />
        {selectActiveTab && <View>{selectActiveTab}</View>}
      </View>
      <CommonDataTable
        canFilterColumns
        columns={getColumns()}
        data={tableData}
        resetPage={searchQuery}
        searchQuery={searchQuery}
        tableMaxHeight={600}
      />
      <CustomModal
        title={`ID: ${showDeleteConfirmation?.record?.id}`}
        visible={showDeleteConfirmation.show}
        hideModal={closeDeleteConfirmationWindow}
      >
        <ConfirmationWindow
          isDelete
          message={`Are you sure you want to delete payment with ID ${showDeleteConfirmation?.record?.id}? If yes, type in delete below`}
          handleNoOnClick={closeDeleteConfirmationWindow}
          handleYesOnClick={handleDelete}
        />
      </CustomModal>
    </View>
  );
};

export default PaymentTable;
