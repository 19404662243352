import { StyleSheet } from "react-native";

export const customModalStyle = (theme) =>
  StyleSheet.create({
    modalWrapper: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0,0,0,0.5)'
    },
    container: {
      backgroundColor: theme.colors.card,
      padding: 32,
      width: "fit-content",
      justifyContent: "center",
      alignSelf: "center",
      borderRadius: 10,
      borderColor: theme.colors.inputborderColor,
      borderWidth: 1,
    },
    bodyContainer: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
    },
    title: {
      fontFamily: theme.customFonts.bold,
      fontSize: 24,
      color: theme.colors.text,
    },
    closeIcon: { color: theme.colors.text },
    bodyContent: { width: "100%" },
  });
