import React from 'react'
import ImportPayments from './ImportPayments'
import { ImportPaymentProvider } from './Reducer/ImportPaymentsContextAPI'


const ImportPaymentsConsumer = () => {
    return (
        <ImportPaymentProvider>
          <ImportPayments />
        </ImportPaymentProvider>
    )
}

export default ImportPaymentsConsumer