import { StyleSheet, Text, View } from "react-native";
import React, { useContext, useEffect, useState } from "react";
import {
  ConfirmationWindow,
  CustomButton,
  CustomModal,
  Loader,
  commonFormStyle,
} from "../../../../components";
import useAxios from "../../../../hooks/useAxios";
import axios from "axios";
import MessageValidation from "../../../../components/MessageValidation";
import {
  BUTTON_TEXTS,
  DEFAULT_ERROR_MSG,
  DEFAULT_FIELD_VALIDATION_MESSAGE,
  INVALID_EMAIL_MSG,
  INVALID_PHONE_NUMBER_MSG,
  MESSAGE_TYPE,
  NEW_PASSWORD_DONT_MATCH_MSG,
} from "../../../../constants/GlobalVariables";
import { useTheme } from "react-native-paper";
import {
  FormCheckBox,
  FormControlInput,
  FormSelect,
} from "../../../../components/FormControl";
import {
  ARTIST_VALUE,
  LABEL_VALUE,
  accountTypeOptions,
} from "./AccountsListingPage.config";
import FormRadioGroup from "../../../../components/FormControl/FormRadio/FormRadioGroup";
import FormRadioButton from "../../../../components/FormControl/FormRadio/FormRadioButton";
import { checkObjectIsEmpty } from "../../../../utils/checkObjectIsEmpty";
import { checkEmailValidation } from "../../../../utils/checkEmailValidation";
import { checkPhoneNumberValidation } from "../../../../utils/checkPhoneNumberValidation";
import { ACCOUNT_URL } from "../../../../constants/GlobalUrls";
import { Entypo } from "@expo/vector-icons";
import { dealFormForLabelStyle } from "../../ContractManager/ContractManagerDealForm/dealFormForLabelStyle";
import AuthContextAPI from "../../../../context/AuthContextAPI";
import { checkNetworkReachable } from "../../../../utils/checkNetworkReachable";

const CreateAccountForm = ({
  record,
  cancelButton,
  triggerTableRefresh,
  isEditMode,
}) => {
  // api hook
  let api = useAxios();
  const { logoutUser } = useContext(AuthContextAPI);
  // cancel token used to cancel the call if component is unmounted or need to programmatically cancel the request
  const cancelTokenSource = axios.CancelToken.source();

  let theme = useTheme();

  const [isLoading, setIsLoading] = useState(false);
  const [showWarningMessage, setShowWarningMessage] = useState(false);

  const [message, setMessage] = useState({
    show: false,
    type: "",
    message: "",
  });

  const [formData, setFormData] = useState({
    username: "",
    email: "",
    phone: "",
    accounttype: "",
    gender: "",
    subscribed: false,
    darkmode: false,
    password: "",
    confirmPassword: "",
  });

  const {
    username,
    email,
    phone,
    accounttype,
    gender,
    subscribed,
    darkmode,
    password,
    confirmPassword,
  } = formData;

  useEffect(() => {
    let isMoulded = true;
    if (isMoulded && isEditMode) {
      setFormData({ ...formData, ...record });
    }
    return () => {
      isMoulded = false;
    };
  }, [record]);

  const resetMessageObj = () => {
    setMessage({
      show: false,
      type: "",
      message: "",
    });
  };

  const onChange = (name, value, selectedName) => {
    if (
      isEditMode &&
      name === "accounttype" &&
      value !== record?.accounttype &&
      (record?.num_of_artists !== 0 || record?.num_of_labels !== 0)
    ) {
      setShowWarningMessage(true);
    } else {
      if (message.show) {
        resetMessageObj();
      }
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleErrorResponse = (error) => {
    checkNetworkReachable();
    if (error?.response?.status === 401) {
      logoutUser();
    } else if (!axios.isCancel(error) && error?.response) {
      if (error?.response?.data?.msg) {
        alert(error?.response?.data?.msg);
      } else {
        alert(DEFAULT_ERROR_MSG);
      }
    }
    setIsLoading(false);
  };

  const onSubmit = async () => {
    let validateData = {
      username,
      email,
      accounttype,
      gender,
      password,
      confirmPassword,
    };
    let validateEditData = {
      username,
      email,
      accounttype,
      gender,
    };

    let isEmpty = isEditMode
      ? checkObjectIsEmpty(validateEditData)
      : checkObjectIsEmpty(validateData);

    if (isEmpty) {
      // Check if all the required fields are not empty.

      if (!checkEmailValidation(email)) {
        // Email validation.
        setMessage({
          show: true,
          type: MESSAGE_TYPE.error,
          message: INVALID_EMAIL_MSG,
        });
        setFormData({
          ...formData,
          email: "",
        });
      } else if (phone && !checkPhoneNumberValidation(phone)) {
        // Phone number validation.
        setMessage({
          show: true,
          type: MESSAGE_TYPE.error,
          message: INVALID_PHONE_NUMBER_MSG,
        });
        setFormData({
          ...formData,
          phone: "",
        });
      } else if (!isEditMode && password !== confirmPassword) {
        // Password match validation
        setMessage({
          show: true,
          type: MESSAGE_TYPE.error,
          message: NEW_PASSWORD_DONT_MATCH_MSG,
        });
        setFormData({
          ...formData,
          password: "",
          confirmPassword: "",
        });
      } else {
        setIsLoading(true);
        const handleSuccessResponse = (res) => {
          if (res?.msg) {
            alert(res.msg);
          }
          triggerTableRefresh();
          cancelButton();
        };
        if (isEditMode) {
          let payload = {
            username,
            email,
            phone,
            gender,
            accounttype,
            subscribed,
            darkmode,
          };
          await api
            .put(ACCOUNT_URL, payload, {
              cancelToken: cancelTokenSource.token,
            })
            .then((res) => {
              handleSuccessResponse(res.data);
            })
            .catch((error) => {
              handleErrorResponse(error);
            });
        } else {
          await api
            .post(ACCOUNT_URL, formData, {
              cancelToken: cancelTokenSource.token,
            })
            .then((res) => {
              handleSuccessResponse(res.data);
            })
            .catch((error) => {
              handleErrorResponse(error);
            });
        }
      }
    } else {
      setMessage({
        show: true,
        type: MESSAGE_TYPE.error,
        message: DEFAULT_FIELD_VALIDATION_MESSAGE,
      });
    }
  };

  const closeShowWarningMessage = () => {
    setShowWarningMessage(false);
  };

  const handleYesOnClick = () => {
    let changeData =
      formData?.accounttype === ARTIST_VALUE ? LABEL_VALUE : ARTIST_VALUE;
    setFormData({ ...formData, accounttype: changeData });
    closeShowWarningMessage();
  };

  return (
    <>
      <Loader loading={isLoading} />
      <View style={commonFormStyle(theme).formContainer}>
        {!showWarningMessage && (
          <>
            <FormControlInput
              required
              label="Username"
              name={"username"}
              value={username}
              onChange={onChange}
              isValidated={message.show && !username}
              disabled={isEditMode}
            />
            <FormControlInput
              required
              label="Email"
              name={"email"}
              value={email}
              onChange={onChange}
              isValidated={message.show && !email}
              disabled={isEditMode}
            />
            <FormControlInput
              label="Phone Number"
              isPhoneNumber
              name={"phone"}
              value={phone}
              onChange={onChange}
            />
            <FormSelect
              required
              label={"Select Account Type"}
              options={accountTypeOptions}
              onChange={onChange}
              value={accounttype}
              name={"accounttype"}
              isValidated={message.show && !accounttype}
            />

            <FormRadioGroup
              required
              label={"Gender"}
              value={gender}
              name={"gender"}
              onChange={onChange}
              isValidated={message.show && !gender}
            >
              <FormRadioButton label={"Male"} value={"male"} />
              <FormRadioButton label={"Female"} value={"female"} />
            </FormRadioGroup>

            <View style={styles.checkBoxesContainer}>
              <FormCheckBox
                label={"Subscribed"}
                name={"subscribed"}
                value={subscribed}
                onChange={onChange}
              />
              <FormCheckBox
                label={"Dark Mode"}
                name={"darkmode"}
                value={darkmode}
                onChange={onChange}
              />
            </View>
            {!isEditMode && (
              <>
                <FormControlInput
                  required
                  secureTextEntry
                  label="Password"
                  name={"password"}
                  value={password}
                  onChange={onChange}
                  isValidated={message.show && !password}
                />
                <FormControlInput
                  required
                  secureTextEntry
                  label="Re-enter Password"
                  name={"confirmPassword"}
                  value={confirmPassword}
                  onChange={onChange}
                  isValidated={message.show && !confirmPassword}
                />
              </>
            )}
            <MessageValidation {...message} />
            {!!!isLoading && (
              <View style={commonFormStyle(theme).actionBtnContainer}>
                <CustomButton
                  onPress={onSubmit}
                  text={BUTTON_TEXTS.SUBMIT}
                  cutomButtonStyle={commonFormStyle(theme).submitBtn}
                />
                <CustomButton
                  text={BUTTON_TEXTS.CANCEL}
                  onPress={cancelButton}
                  cutomButtonStyle={commonFormStyle(theme).backBtn}
                />
              </View>
            )}
          </>
        )}
        <CustomModal
          customTitle={
            <Entypo name="warning" size={24} color={theme.colors.warning} />
          }
          visible={showWarningMessage}
          hideModal={closeShowWarningMessage}
        >
          <ConfirmationWindow
            customMessage={
              <Text style={dealFormForLabelStyle(theme).messageContainer}>
                Switching the Account Type will detach the currently mapped{" "}
                {record?.accounttype}(s) from this user account.
              </Text>
            }
            handleNoOnClick={closeShowWarningMessage}
            handleYesOnClick={handleYesOnClick}
          />
        </CustomModal>
      </View>
    </>
  );
};

export default CreateAccountForm;

const styles = StyleSheet.create({
  checkBoxesContainer: { flexDirection: "row", gap: 20, marginLeft: -8 },
});
