import React, { useContext, useEffect, useState } from "react";
import {
  ACTION_BUTTON_TYPE,
  CONDFIRM_EDIT_MESSAGE,
  DEFAULT_ERROR_MSG,
  DOWNLOAD_CSV_TEXT,
} from "../../../../constants/GlobalVariables";
import {
  ActionsButtonIcons,
  CommonDataTable,
  commonStatisButtonCustomStyle,
  commonStatisticButtonStyle,
  ConfirmationWindow,
  CustomButton,
  CustomModal,
  Statistics,
  WebContentContainerWrapper,
} from "../../../../components";
import { View } from "react-native";
import { Searchbar, withTheme } from "react-native-paper";
import { config } from "../Advances.config";
import {
  ADVANCES_URL,
  EXPORT_ADVANCES_URL,
} from "../../../../constants/GlobalUrls";
import useAxios from "../../../../hooks/useAxios";
import axios from "axios";
import AdvancesForm from "../../ContractManager/ContractManagerDealForm/AdvancesForm";
import dayjs from "dayjs";
import AuthContextAPI from "../../../../context/AuthContextAPI";
import { checkNetworkReachable } from "../../../../utils/checkNetworkReachable";
let uuid = require("uuid");

const AdvancesListingPage = ({ theme }) => {
  // api hook
  let api = useAxios();

  const { logoutUser } = useContext(AuthContextAPI);

  // cancel token used to cancel the call if component is unmounted or need to programmatically cancel the request
  const cancelTokenSource = axios.CancelToken.source();
  const [showAdvancesForm, setShowAdvancesForm] = useState({
    showForm: false,
    record: {},
  });
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState({
    show: false,
    record: {},
  });
  const [refreshTable, setRefreshTable] = useState(false);
  const [showEditConfirmation, setShowEditConfirmation] = useState(false);

  useEffect(() => {
    let isMoulded = true;
    if (isMoulded) {
      getAllAdvances();
    }
    return () => {
      isMoulded = false;
      cancelTokenSource.cancel();
    };
    // eslint-disable-next-line
  }, [refreshTable]);

  const { showForm, record } = showAdvancesForm;
  const onChangeSearch = (query) => setSearchQuery(query);

  const openShowAdvancesForm = (rowdData = {}) => {
    let {
      id,
      type,
      advancedate,
      amount,
      currency,
      currencyid,
      clientid,
      contractid,
      dealid,
    } = rowdData;

    let d = rowdData?.advancedate && rowdData?.advancedate.split("/");
    let dat = new Date(d[2] + "/" + d[1] + "/" + d[0]);
    let tempData = {
      id: id,
      type: type,
      rawDate: dat,
      advancedate: advancedate,
      amount: amount,
      currency: currency,
      currencyid: currencyid,
      clientid: clientid || contractid || dealid,
    };

    setShowAdvancesForm({
      showForm: true,
      record: tempData,
    });
  };
  const closeShowAdvancesForm = () => {
    setShowAdvancesForm({
      ...showAdvancesForm,
      showForm: false,
    });
  };

  const closeDeleteConfirmationWindow = () => {
    setShowDeleteConfirmation({ ...showDeleteConfirmation, show: false });
  };
  const openDeleteConfirmationWindow = (rowData) => {
    setShowDeleteConfirmation({ show: true, record: rowData });
  };

  const closeShowEditConfirmationWindow = () => {
    setShowEditConfirmation(false);
  };
  const openShowEditConfirmationWindow = () => {
    setShowEditConfirmation(true);
  };

  const onEditConfirm = () => {
    editAdvances(showAdvancesForm.record);
  };

  const editAdvances = async (revised) => {
    let requestData = {
      original: record,
      revised,
      compare_data: true,
    };
    if (showEditConfirmation) {
      requestData.compare_data = false;
      closeShowEditConfirmationWindow();
    } else {
      closeShowAdvancesForm();
    }
    setIsLoading(true);
    const handleSuccessResponse = (res) => {
      if (res?.msg) {
        alert(res.msg);
        setRefreshTable(!refreshTable);
      }
    };

    const handleErrorResponse = (error) => {
      checkNetworkReachable();
      if (error?.response?.status === 401) {
        logoutUser();
      } else if (error?.response?.status === 409) {
        openShowEditConfirmationWindow();
      } else if (!axios.isCancel(error) && error?.response) {
        alert(DEFAULT_ERROR_MSG);
      }
      setIsLoading(false);
    };

    let url = `${ADVANCES_URL}${revised.id}/`;
    await api
      .put(url, requestData, {
        cancelToken: cancelTokenSource.token,
      })
      .then((res) => {
        handleSuccessResponse(res.data);
      })
      .catch((error) => {
        handleErrorResponse(error);
      });
  };

  const handleDelete = async () => {
    closeDeleteConfirmationWindow();
    setIsLoading(true);
    const handleSuccessResponse = (res) => {
      if (res?.msg) {
        alert(res.msg);
        setRefreshTable(!refreshTable);
      }
    };

    const handleErrorResponse = (error) => {
      checkNetworkReachable();
      if (error?.response?.status === 401) {
        logoutUser();
      } else if (!axios.isCancel(error) && error?.response) {
        alert(DEFAULT_ERROR_MSG);
      }
      setIsLoading(false);
    };

    let url = `${ADVANCES_URL}${showDeleteConfirmation.record.id}/`;
    await api
      .delete(url, {
        cancelToken: cancelTokenSource.token,
      })
      .then((res) => {
        handleSuccessResponse(res.data);
      })
      .catch((error) => {
        handleErrorResponse(error);
      });
  };

  const getAllAdvances = async () => {
    const handleSuccessResponse = (res) => {
      setTableData(res);
    };

    await api
      .get(ADVANCES_URL, {
        cancelToken: cancelTokenSource.token,
      })
      .then((res) => {
        handleSuccessResponse(res.data);
      })
      .catch((error) => {
        checkNetworkReachable();
        if (error?.response?.status === 401) {
          logoutUser();
        } else if (!axios.isCancel(error) && error?.response) {
          alert(DEFAULT_ERROR_MSG);
        }
      });

    setIsLoading(false);
  };

  const onClickDownloadCSV = async () => {
    setIsLoading(true);
    await api
      .get(EXPORT_ADVANCES_URL, {
        responseType: "blob",
        cancelToken: cancelTokenSource.token,
      })
      .then((response) => {
        //Create a Blob from the csv Stream
        const file = new Blob([response.data], { type: "text/csv" });
        //Build a URL from the file
        var csvURL = window.URL.createObjectURL(file);
        let tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", `advance_${uuid.v4()}.csv`);
        tempLink.click();
        setIsLoading(false);
      })
      .catch((error) => {
        checkNetworkReachable();
        setIsLoading(false);
        if (error?.response?.status === 401) {
          logoutUser();
        } else if (!axios.isCancel(error) && error?.response) {
          alert(DEFAULT_ERROR_MSG);
        }
      });
  };

  let columns = [
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
      width: 0.5,
      sort: true,
      search: true,
      // bold: true,
    },
    {
      key: "category",
      title: "Advance Category",
      dataIndex: "type",
      sort: true,
      width: 1.5,
      filter: true,
      // bold: true,
    },
    {
      key: "common_id",
      title: "Client ID/Contract ID/Deal ID",
      dataIndex: "common_id",
      sort: true,
      width: 2,
      filter: true,
    },
    {
      key: "name",
      title: "Client Individual/Company Name",
      dataIndex: "name",
      sort: true,
      search: true,
      width: 3,
      filter: true,
      // bold: true,
    },
    {
      key: "advancedate_raw",
      title: "Advance Date",
      dataIndex: "advancedate_raw",
      sort: true,
      width: 1.5,
      isDate: true,
      customToolTip: (date) => {
        return date ? dayjs(date).format("DD/MM/YYYY") : null
      },
      render: (date) => {
        return date ? dayjs(date).format("DD/MM/YYYY") : null
      },
    },
    {
      key: "amount",
      title: "Amount",
      dataIndex: "amount",
      sort: true,
      width: 1,
    },
    {
      key: "currencycode",
      title: "Currency",
      dataIndex: "currencycode",
      sort: true,
      width: 1,
      filter: true,
    },
    {
      key: ACTION_BUTTON_TYPE.EDIT,
      title: "Edit",
      dataIndex: "key",
      width: 0.3667,
      center: true,
      hideToolTip: true,
      render: (id, row) => {
        return (
          <ActionsButtonIcons
            buttonType={ACTION_BUTTON_TYPE.EDIT}
            handleOnPress={() => {
              openShowAdvancesForm(row);
            }}
          />
        );
      },
    },
    {
      key: ACTION_BUTTON_TYPE.DELETE,
      title: "Delete",
      dataIndex: "key",
      width: 0.3667,
      center: true,
      hideToolTip: true,
      render: (id, row) => {
        return (
          <ActionsButtonIcons
            buttonType={ACTION_BUTTON_TYPE.DELETE}
            handleOnPress={() => {
              openDeleteConfirmationWindow(row);
            }}
          />
        );
      },
    },
  ];

  return (
    <WebContentContainerWrapper title={config.Title} loading={isLoading}>
      <View style={commonStatisticButtonStyle(theme).statisticContainer}>
        {config.STATISITICS_ARR.map((item) => {
          return (
            <Statistics
              key={item.value}
              label={item.label}
              value={tableData.length || 0}
            />
          );
        })}
        <CustomButton
          text={DOWNLOAD_CSV_TEXT}
          cutomButtonStyle={commonStatisButtonCustomStyle(theme).downloadBtn}
          textStyle={commonStatisButtonCustomStyle(theme).text}
          onPress={onClickDownloadCSV}
        />
      </View>
      <Searchbar
        placeholder={config.SEARCH_PLACEHOLDER}
        onChangeText={onChangeSearch}
        value={searchQuery}
        style={commonStatisticButtonStyle(theme, 522).searchBar}
        inputStyle={commonStatisticButtonStyle(theme).searchBarInput}
      />
      <CommonDataTable
        canFilterColumns
        columns={columns}
        data={tableData}
        resetPage={searchQuery}
        searchQuery={searchQuery}
      />
      <CustomModal
        title={`${Object.keys(record).length > 0 ? `Edit Advance: ${record?.id}` : "New Advance"}`}
        visible={showForm}
        hideModal={closeShowAdvancesForm}
      >
        <AdvancesForm
          data={record}
          cancelButton={closeShowAdvancesForm}
          getAdvanceData={editAdvances}
          advanceFor={record?.type}
        />
      </CustomModal>
      <CustomModal
        title={`Confirm`}
        visible={showEditConfirmation}
        hideModal={closeShowEditConfirmationWindow}
      >
        <ConfirmationWindow
          message={`${CONDFIRM_EDIT_MESSAGE}`}
          handleNoOnClick={closeShowEditConfirmationWindow}
          handleYesOnClick={onEditConfirm}
        />
      </CustomModal>
      <CustomModal
        title={`ID: ${showDeleteConfirmation?.record?.id}`}
        visible={showDeleteConfirmation.show}
        hideModal={closeDeleteConfirmationWindow}
      >
        <ConfirmationWindow
          isDelete
          message={`Are you sure you want to delete ${showDeleteConfirmation?.record?.type} advance in the amount of ${showDeleteConfirmation?.record?.amount} ${showDeleteConfirmation?.record?.currencycode}? If yes, type in delete below`}
          handleNoOnClick={closeDeleteConfirmationWindow}
          handleYesOnClick={handleDelete}
        />
      </CustomModal>
    </WebContentContainerWrapper>
  );
};

export default withTheme(AdvancesListingPage);
