import React, { useContext, useEffect } from 'react'
import { WebContentContainerWrapper } from '../../../components'
import PaymentContextAPI from './Reducer/PaymentsContextAPI';
import { RETURN_TO_LANDING_PAGE } from './Reducer/actions';
import { config } from './payment.config';
import { config as invoiceConfig } from '../InvoicesData/invoice.config';
import InvoiceDetailForPaymentTable from './InvoiceDetailForPaymentTable';
import InvoicesDataContextAPI from '../InvoicesData/InvoicesDataContextAPI';
import { SELECTED_ROW } from '../InvoicesData/reducer/actions';
import RelatedPaymentsTable from './RelatedPaymentsTable';

const PaymentDetailPage = () => {

    let {
        state: { selectedRowData, loading, activeTab },
        pageName,
        getInvoiceDetailsTableData,
        dispatch: paymentDispatch,
    } = useContext(PaymentContextAPI);

    let { state: invoiceState, dispatch: invoiceDispatch } = useContext(InvoicesDataContextAPI);

    const gotToLandingPage = () => {
        paymentDispatch({ type: RETURN_TO_LANDING_PAGE });
    };


    const goToInvoiceDetailPage = () => {
        paymentDispatch({ type: SELECTED_ROW, payload: {} });
    };

    const goToInvoiceLandingPage = () => {
        paymentDispatch({ type: SELECTED_ROW, payload: {} });
        invoiceDispatch({ type: RETURN_TO_LANDING_PAGE });
    };

    useEffect(() => {
        let isMoulded = true
        if (isMoulded) {
            getInvoiceDetailsTableData()
        }
        return () => {
            isMoulded = false
        };
    }, []);

    const paymentBreadCrumbData = [
        {
            title: config.TITLE,
            onClickPress: gotToLandingPage,
        },
        {
            title: `Payment Details #${selectedRowData?.id} (${activeTab})`,
            active: true,
        },
    ];

    const invoiceBreadCrumbData = [
        {
            title: invoiceConfig.TITLE,
            onClickPress: goToInvoiceLandingPage,
        },
        {
            title: `Invoice Details #${invoiceState?.selectedRowData?.id}`,
            onClickPress: goToInvoiceDetailPage,
        },
        {
            title: `Payment Details #${selectedRowData?.id} (${activeTab})`,
            active: true,
        },
    ];

    return (
        <WebContentContainerWrapper
            loading={loading}
            breadCrumbData={
                pageName === config.TITLE
                    ? paymentBreadCrumbData
                    : invoiceBreadCrumbData
            }
        >
            <RelatedPaymentsTable />
            <InvoiceDetailForPaymentTable pageName={pageName} />
        </WebContentContainerWrapper>
    );
}

export default PaymentDetailPage