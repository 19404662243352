import Constants from "expo-constants";

const FIRST_VERSION = "/v1/";

// Backend domain endpoint
export const BACKEND_DOMAIN = Constants.manifest.extra.apiUrl + FIRST_VERSION;

// access login/logout token endpoints
export const ACCESS_TOKEN_URL = BACKEND_DOMAIN + "token/";
export const REFRESH_TOKEN_URL = BACKEND_DOMAIN + "token/refresh/";
export const LOGOUT_URL = BACKEND_DOMAIN + "logout/";

// Password endpoints
export const CHANGE_PASSWORD = BACKEND_DOMAIN + "users/change-password/";
export const FORGOT_PASSWORD = BACKEND_DOMAIN + "password_reset/";
export const NEW_PASSWORD = BACKEND_DOMAIN + "password_reset/confirm/";
export const VALIDATE_TOKEN_NEW_PASSWORD =
  BACKEND_DOMAIN + "password_reset/validate_token/";

// Users endpoints
export const USER_DETIALS = BACKEND_DOMAIN + "users/";
export const CONTACT_SUPPORT_URL = USER_DETIALS + "support/";
export const SIGN_UP_URL = USER_DETIALS + "signup/";
export const UPLOAD_PROFILE_PICTURE = USER_DETIALS + "profile/";

// Artists endpoints
export const ARTIST_DETAILS = BACKEND_DOMAIN + "artists/";

// Contract endpoints
export const CONTRACTS_URL = BACKEND_DOMAIN + "contracts/";
export const CONTRACTS_TYPES = CONTRACTS_URL + "contracttype/";
export const GET_ALL_SERVICE_PROVIDER = CONTRACTS_URL + "serviceprovider/";
export const LABELS_URL = BACKEND_DOMAIN + "labels/";
export const TRACK_URL = BACKEND_DOMAIN + "release_tracks_upc/";
export const RELEASES_URL = BACKEND_DOMAIN + "releases/";
export const CONTRACTS_RELEASES_URL = BACKEND_DOMAIN + "contract_releases/";
export const EXPORT_CONTRACT_URL =
  BACKEND_DOMAIN + "contracts/download/contract/";
export const UPLOAD_PDF_CONTRACT_URL = CONTRACTS_URL + "upload/";
export const DOWNLOAD_PDF_CONTRACT_URL =
  CONTRACTS_URL + "download/pdf_contracts/";
export const PDF_TYPES_URL = CONTRACTS_URL + "pdftypes/";
export const GET_DEAL_TYPE_URL = CONTRACTS_URL + "dealtype/";

// Client endpoints
export const CONTRACTS_REGISTRATION = CONTRACTS_URL + "registration/";
export const GET_REGISTRATION_OVERVIEW =
  CONTRACTS_URL + "registration-overview/";
export const EXPORT_CLIENT_URL = BACKEND_DOMAIN + "contracts/download/client/";
export const GET_REPORT_VERSIONS_URL =
  BACKEND_DOMAIN + "reports/report_version/";
export const GET_PAYMENT_SERVICE_PROVIDER = "payments/payment_service_provider/";

// Advance endpoints
export const ADVANCES_URL = CONTRACTS_URL + "advances/";
export const CURRENCY_URL = CONTRACTS_URL + "currency/";
export const EXPORT_ADVANCES_URL = CONTRACTS_URL + "download/advance/";

// Expense endpoints
export const EXPENSES_URL = CONTRACTS_URL + "expenses/";
export const EXPORT_EXPENSES_URL = CONTRACTS_URL + "download/expense/";
export const EXPENSE_TYPE_URL = CONTRACTS_URL + "expensetype/"

//Deals endpoints
export const CONTRACTS_DEALS_ADVANCES_URL =
  CONTRACTS_URL + "contracts_deals_advances/";
export const DEAL_DASHBOARD_URL = CONTRACTS_URL + "deals/";
export const EXPORT_DEAL_URL = CONTRACTS_URL + "download/deal/";

// Landing page APIs
export const DASHBOARD_CARD_URL = USER_DETIALS + "dashboard/";
export const CHARTMETRIC_URL = BACKEND_DOMAIN + "chartmetric/";
export const STORES = CHARTMETRIC_URL + "stores/";
export const COUNTRY_RECORDS_URL = CHARTMETRIC_URL + "get_country_records/";
export const GENDER_WISE_RECORDS_URL =
  CHARTMETRIC_URL + "get_genderwise_records/";
export const AGE_WISE_RECORD_URL = CHARTMETRIC_URL + "get_agewise_records/";
export const FILTER_CHART_TRACK_DATA_URL = BACKEND_DOMAIN + "chart_tracks/";

// Asset endpoints
export const ASSET_URL = BACKEND_DOMAIN + "assets/";
export const GET_ALL_ASSET_DROPDOWN_URL = ASSET_URL + "get_all_assets/";
export const GET_CHANNEL_DROPDOWN_URL = ASSET_URL + "get_all_channels/";

// Accounts page endpoints
export const USER_URL = BACKEND_DOMAIN + "users/";
export const GET_ALL_USERS_LIST_URL = USER_URL + "get_users_list/";
export const ACCOUNT_URL = USER_URL + "account/";
export const GET_ALL_CLIENT_URL = USER_URL + "get_all_client_list/";
export const GET_ALL_ARTIST_URL = USER_URL + "get_all_artist_list/";
export const GET_ALL_LABELS_URL = USER_URL + "get_all_label_list/";
export const GET_USERS_CLIENT_URL = USER_URL + "get_users_client_list/";
export const GET_USERS_ALL_ARTIST_URL = USER_URL + "get_users_artist_list/";
export const GET_USERS_ALL_LABELS_URL = USER_URL + "get_users_label_list/";
export const UPDATE_USER_ACCOUNT_DATA = USER_URL + "update_user_account_data/";
export const DOWNLOAD_USER_ACCOUNT_DATA =
  CONTRACTS_URL + "download/useraccount/";

// Missing DSP deals endpoints
export const GET_ALL_MISSING_LABELS_URL = BACKEND_DOMAIN + "missing_labels/";
export const GET_ALL_MISSING_TRACK_ON_UPC_URL =
  BACKEND_DOMAIN + "missing_tracks_on_upc/";
export const GET_ALL_MISSING_RELEASES = BACKEND_DOMAIN + "missing_releases/";
export const DOWNLOAD_MISSING_DSP_DEALS_URL =
  CONTRACTS_URL + "download/missing_releases/";
export const GET_DSP_CONTRACTS_URL = CONTRACTS_URL + "dsp/";
export const CONTRACTS_TYPE_DSP_URL = CONTRACTS_URL + "contracttype/dsp/";

// Reports endpoints
export const REPORTS_URL = BACKEND_DOMAIN + "reports/";
export const GET_REPORTING_PERIOD_URL = REPORTS_URL + "reporting_period/";
export const GET_CLIENTS_FILTER_URL = REPORTS_URL + "clients_filter/";
export const GET_DEALS_FILTER_URL = REPORTS_URL + "deals_filter/";
export const GET_CONTRACTS_FILTER_URL = REPORTS_URL + "contracts_filter/";
export const GET_LABELS_FILTER_URL = REPORTS_URL + "labels_filter/";
export const GET_RELEASES_FILTER_URL = REPORTS_URL + "releases_filter/";
export const GET_TRACKS_FILTER_URL = REPORTS_URL + "tracks_filter/";

export const TRIGGER_PROCESS_PENDING_REPORTS =
  REPORTS_URL + "process_pending_reports/";

export const GET_REPORT_PREVIEW_URL =
  REPORTS_URL + "revenue_data/preview_report/";

// Overlapping DSP Deals
export const GET_OVERLAPPING_LABELS_URL =
  BACKEND_DOMAIN + "overlapping_labels/";
export const GET_OVERLAPPING_RELEASES_URL =
  BACKEND_DOMAIN + "overlapping_releases/";
export const GET_OVERLAPPING_RELEASE_TRACKS_URL =
  BACKEND_DOMAIN + "overlapping_release_tracks/";

// Royalties endpoints
export const ROYALTIES_URL = BACKEND_DOMAIN + "royalties/";
export const GET_ROYALTIES_SOURCE_URL = ROYALTIES_URL + "source_royalties/";
export const GET_ROYALTIES_PLATFORM_URL = ROYALTIES_URL + "store_royalties/";
export const GET_ROYALTIES_REPORTING_PERIOD_URL =
  ROYALTIES_URL + "reporting_period_royalties/";
export const DOWNLOAD_ROYALTIES_URL = ROYALTIES_URL + "download/royalties/";

// Invoice / Adjustments
export const GET_CLIENT_FOR_INVOICES = BACKEND_DOMAIN + "invoices/clients_filter/"
export const INVOICES_URL = BACKEND_DOMAIN + "invoices/";
export const COMPARE_MATRIX_URL = INVOICES_URL + "invoices_adjustments/";
export const GET_INVOICE_TYPES_URL = INVOICES_URL + "invoice_type/";
export const UPDATE_INVOICE_URL = INVOICES_URL + "update_invoice_type/";
export const GET_INVOICE_PAYMENT_DETAILS = INVOICES_URL + "invoice_details/"
export const DOWNLOAD_INVOICE_URL = INVOICES_URL + "download/invoice";
export const GET_SERVICES_URL = INVOICES_URL + "services/";
export const GET_ADJUSTMENT_YEARS_FILTER = INVOICES_URL + "years_filter/";

// Employee Accounts
export const GET_ALL_EMPLOYEE_ACCOUNTS = USER_URL + "employee_accounts/";
export const GET_USER_ROLES_URL = USER_URL + "user_roles/";
export const GET_USER_ROLE_PERMISSIONS_URL = USER_URL + "permissions/";
export const DOWNLOAD_EMPLOYEE_ACCOUNT_URL =
  CONTRACTS_URL + "download/employeeaccount/";
export const UPDATE_EMPLOYEE_PASSWORD = USER_URL + "update_employee_password/";

// Report Version
export const GET_ALL_REPORT_VERSIONS_URL = REPORTS_URL + "report_version/";
export const DOWNLOAD_REPORT_VERISON_URL =
  REPORTS_URL + "report_version/download/report_version";

// Catalogue
export const CATALOGUE_URL = "catalogue/";
export const GET_CATALOGUE_OVERVIEW_URL = CATALOGUE_URL + "catalogue-overview/";
export const GET_CATALOGUE_RELEASE_URL = CATALOGUE_URL + "all_release_data/";
export const GET_CATALOGUE_TRACK_URL = CATALOGUE_URL + "all_track_data/";
export const DOWNLOAD_RELEASE_URL =
  CATALOGUE_URL + "download/all_release_data/";
export const DOWNLOAD_TRACK_URL = CATALOGUE_URL + "download/all_track_data/";

// Start Payments
export const PAYMENT_URL = "payments/";

// Page: Import Payments
export const IMPORTED_PAYMENTS_URL = PAYMENT_URL + "imported/";
export const POST_CSV_URL = PAYMENT_URL + "import/";
export const DOWNLOAD_IMPORTED_PAYMENT_CSV_URL = PAYMENT_URL + "download/imported/";

// Import Payments Filters
export const GET_CLIENTS_FILTER_IMPORTED_PAYMENTS_URL = IMPORTED_PAYMENTS_URL + "clients_filter/";
export const GET_YEARS_FILTER_IMPORTED_PAYMENTS_URL = IMPORTED_PAYMENTS_URL + "years_filter/";
export const GET_ALL_CURRENCY_IMPORTED_PAYMENTS_URL = IMPORTED_PAYMENTS_URL + "currency_filter/";

// Page: Payments
// Not merged URLs
export const GET_NOT_MERGED_PAYMENTS_URL = PAYMENT_URL + "not_merged/";
export const GET_NOT_MERGED_CLIENTS_FILTER_PAYMENTS_URL = GET_NOT_MERGED_PAYMENTS_URL + "clients_filter/";
export const GET_NOT_MERGED_YEARS_FILTER_URL = GET_NOT_MERGED_PAYMENTS_URL + "years_filter/";
export const GET_NOT_MERGED_ALL_CURRENCY_URL = GET_NOT_MERGED_PAYMENTS_URL + "currency_filter/";
export const DOWNLOAD_NOT_MERGED_CSV_URL = PAYMENT_URL + "download/not_merged/";
// Merged URLs
export const GET_MERGED_PAYMENTS_URL = PAYMENT_URL + "merged/";
export const GET_MERGED_CLIENTS_FILTER_PAYMENTS_URL = GET_MERGED_PAYMENTS_URL + "clients_filter/";
export const GET_MERGED_ALL_CURRENCY_URL = GET_MERGED_PAYMENTS_URL + "currency_filter/";
export const DOWNLOAD_MERGED_CSV_URL = PAYMENT_URL + "download/merged/";
// Page: Payments details
export const ADD_INVOICE_TO_PAYMENT_URL = PAYMENT_URL + "invoice_payment/";
export const GET_INVOICE_DETAILS_TABLE_FOR_PAYMENT_URL = PAYMENT_URL + "payment_details/";

// Page: Merge Payments
export const MERGE_PAYMENT_URL = PAYMENT_URL + "merge_payments/";
// End Payments
