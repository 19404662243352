import axios from "axios";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { View } from "react-native";
import { withTheme } from "react-native-paper";
import {
  commonFormStyle,
  CustomButton,
  CustomModal,
  WebContentContainerWrapper,
  ConfirmationWindow,
} from "../../../../components";
import {
  FormCheckBox,
  FormControlInput,
  FormDatePicker,
  FormSelect,
} from "../../../../components/FormControl";
import MessageValidation from "../../../../components/MessageValidation";
import {
  CONTRACTS_TYPES,
  CONTRACTS_URL,
  CONTRACTS_DEALS_ADVANCES_URL,
} from "../../../../constants/GlobalUrls";
import {
  BUTTON_TEXTS,
  CANCEL_CONTRACT,
  CONDFIRM_EDIT_MESSAGE,
  DEFAULT_ERROR_MSG,
  DEFAULT_REQUIRED_VALIDATION_MESSAGE,
  MESSAGE_TYPE,
} from "../../../../constants/GlobalVariables";
import ContractFormContextAPI from "../../../../context/ContractFormContextAPI";
import useAxios from "../../../../hooks/useAxios";
import { checkObjectIsEmpty } from "../../../../utils/checkObjectIsEmpty";
import { config } from "../ContractManager.config";
import AdvancesTable from "../ContractManagerDealForm/AdvancesTable";
import { ADVANCES_TYPES } from "../ContractManagerDealForm/ContractManagerDealForm.config";
import PDFTable from "../PDFTable/PDFTable";
import ContractManagerDealTable from "./ContractManagerDealTable";
import { config as missingDspDealsConfig } from "../../MissingDspDeals/missingDspDeals.config";
import AuthContextAPI from "../../../../context/AuthContextAPI";
import { checkNetworkReachable } from "../../../../utils/checkNetworkReachable";
import ExpensesTable from "../ContractManagerDealForm/ExpensesTable";

const ContractManagerForm = ({
  isEditMode,
  changeShowPage,
  recordData,
  getDealRecordData,
  theme,
  hasDealFormPrefillData,
  missingDspPage = false,
  preSelectedContracttypeid = "",
  landingPageTitle = "Contracts",
}) => {
  // api hook
  let api = useAxios();

  const { logoutUser } = useContext(AuthContextAPI);

  // cancel token used to cancel the call if component is unmounted or need to programmatically cancel the request
  const cancelTokenSource = axios.CancelToken.source();

  const { formData, setFormData, original, setOriginal } = useContext(
    ContractFormContextAPI
  );
  const [showEditConfirmation, setShowEditConfirmation] = useState(false);
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [contractTypeOptions, setContractTypeOptions] = useState([]);

  const [message, setMessage] = useState({
    show: false,
    type: "",
    message: "",
  });

  const {
    contracttypeid,
    contractnumber,
    rawContractDate,
    contractdate,
    duration,
    renewal,
    autorenewal,
  } = formData;

  useEffect(() => {
    let isMoulded = true;
    if (isMoulded && isEditMode && contracttypeid === "") {
      getIndividualContract();
    }
    return () => {
      isMoulded = false;
      cancelTokenSource.cancel();
    };
  }, []);

  useEffect(() => {
    let isMoulded = true;
    if (isMoulded) {
      getContractTypes();
    }
    return () => {
      isMoulded = false;
      cancelTokenSource.cancel();
    };
  }, []);

  const getIndividualContract = async () => {
    setIsLoading(true);
    const handleSuccessResponse = (res) => {
      let data = res && res[0];
      if (original.contracttypeid === "") {
        let tempdata = JSON.parse(JSON.stringify(data));
        setOriginal(tempdata);
      }
      if (data.contractdate) {
        let d = data.contractdate && data.contractdate.split("/");
        let dat = new Date(d[2] + "/" + d[1] + "/" + d[0]);
        data = {
          ...data,
          rawContractDate: dat,
        };
      }
      setFormData({ ...data });
    };
    let url = CONTRACTS_DEALS_ADVANCES_URL + recordData?.id + "/";
    await api
      .get(url, {
        cancelToken: cancelTokenSource.token,
      })
      .then((res) => {
        handleSuccessResponse(res.data);
      })
      .catch((error) => {
        checkNetworkReachable();
        if (error?.response?.status === 401) {
          logoutUser();
        } else if (!axios.isCancel(error) && error?.response) {
          alert(DEFAULT_ERROR_MSG);
        }
      });
    if (hasDealFormPrefillData) {
      getDealRecordData({});
      gotToDealPage();
    } else {
      setIsLoading(false);
    }
  };

  const closeShowEditConfirmationWindow = () => {
    setShowEditConfirmation(false);
  };
  const openShowEditConfirmationWindow = () => {
    setShowEditConfirmation(true);
  };

  const onSubmit = async () => {
    let requiredKeys = {
      contracttypeid,
      contractnumber,
      contractdate,
      duration,
      renewal,
    };
    let isEmpty = checkObjectIsEmpty(requiredKeys);
    if (isEmpty) {
      setIsLoading(true);
      const handleSuccessResponse = (res) => {
        if (res?.msg) {
          alert(res.msg);
        }
        if (missingDspPage) {
          changeShowPage(config.pages.LANDING, true);
        } else {
          gotToLandingPage();
        }
      };

      const handleEditErrorResponse = (error) => {
        checkNetworkReachable();
        if (error?.response?.status === 401) {
          logoutUser();
        } else if (!axios.isCancel(error) && error?.response) {
          setIsLoading(false);
          if (error?.response.status === 409) {
            openShowEditConfirmationWindow();
          } else {
            alert(error?.response?.data?.msg || DEFAULT_ERROR_MSG);
          }
        }
      };

      const handleErrorResponse = (error) => {
        checkNetworkReachable();
        if (error?.response?.status === 401) {
          logoutUser();
        } else if (!axios.isCancel(error) && error?.response) {
          alert(error?.response?.data?.msg || DEFAULT_ERROR_MSG);
        }
        setIsLoading(false);
      };

      let requestFormData = JSON.parse(JSON.stringify(formData));
      delete requestFormData.rawContractDate;

      if (isEditMode) {
        let requestData = {
          original,
          revised: requestFormData,
          compare_data: true,
        };
        if (showEditConfirmation) {
          requestData.compare_data = false;
          closeShowEditConfirmationWindow();
        }
        let url = `${CONTRACTS_URL}${formData.id}/`;
        await api
          .put(url, requestData, {
            cancelToken: cancelTokenSource.token,
          })
          .then((res) => {
            handleSuccessResponse(res.data);
          })
          .catch((error) => {
            handleEditErrorResponse(error);
          });
      } else {
        await api
          .post(CONTRACTS_URL, requestFormData, {
            cancelToken: cancelTokenSource.token,
          })
          .then((res) => {
            handleSuccessResponse(res.data);
          })
          .catch((error) => {
            handleErrorResponse(error);
          });
      }
    } else {
      setMessage({
        show: true,
        type: MESSAGE_TYPE.error,
        message: DEFAULT_REQUIRED_VALIDATION_MESSAGE,
      });
    }
  };

  const closeCancelConfirmationWindow = () => {
    setShowCancelConfirmation(false);
  };
  const openCancelConfirmationWindow = () => {
    setShowCancelConfirmation(true);
  };

  const getContractTypes = async () => {
    if (!isEditMode && hasDealFormPrefillData) {
      setIsLoading(true);
      if (preSelectedContracttypeid) {
        if (contracttypeid === "") {
          onChange("contracttypeid", preSelectedContracttypeid);
          getDealRecordData({});
          gotToDealPage();
        }
      }
    }
    const handleSuccessResponse = (res) => {
      setContractTypeOptions(res);
    };

    await api
      .get(CONTRACTS_TYPES, {
        cancelToken: cancelTokenSource.token,
      })
      .then((res) => {
        handleSuccessResponse(res.data);
      })
      .catch((error) => {
        checkNetworkReachable();
        if (error?.response?.status === 401) {
          logoutUser();
        } else if (!axios.isCancel(error) && error?.response) {
          alert(DEFAULT_ERROR_MSG);
        }
      });
  };

  const resetMessageObj = () => {
    setMessage({
      show: false,
      type: "",
      message: "",
    });
  };

  const onChange = (name, value) => {
    if (message.show) {
      resetMessageObj();
    }
    if (name === "clientType") {
      resetMessageObj();
      setFormData({ ...formData, [name]: value });
    } else if (name === "rawContractDate") {
      setFormData({
        ...formData,
        [name]: value,
        contractdate: dayjs(value).format("DD/MM/YYYY"),
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const gotToLandingPage = () => {
    changeShowPage(config.pages.LANDING);
  };
  const gotToDealPage = () => {
    changeShowPage(config.pages.DEAL_FORM);
  };

  const getAdvanceData = (advances) => {
    onChange("advance_data", advances);
  };

  const getExpenseData = (expenses) => {
    onChange("expense_data", expenses);
  };

  const breadCrumbData = [
    {
      title:
        hasDealFormPrefillData || missingDspPage
          ? missingDspDealsConfig.Title
          : landingPageTitle,
      onClickPress: gotToLandingPage,
    },
    {
      title: isEditMode ? `Edit Contract #${recordData?.id}` : "New Contract",
      active: true,
    },
  ];

  return (
    <WebContentContainerWrapper
      breadCrumbData={breadCrumbData}
      loading={isLoading}
    >
      <View style={commonFormStyle(theme).formContainer}>
        <FormSelect
          required
          name={"contracttypeid"}
          label={"Select Contract Type"}
          options={contractTypeOptions}
          optionName="contracttypename"
          optionValue="id"
          onChange={onChange}
          disabled={
            isEditMode || formData?.deals_data.length > 0 || missingDspPage
          }
          // searchable
          value={contracttypeid}
          isValidated={message.show && !contracttypeid}
        />
        <FormControlInput
          required
          label="Contract Number"
          name={"contractnumber"}
          value={contractnumber}
          onChange={onChange}
          isValidated={message.show && !contractnumber}
        />
        <FormDatePicker
          required
          label="Select Contract Date"
          name={"rawContractDate"}
          value={rawContractDate}
          onChange={onChange}
          isValidated={message.show && !rawContractDate}
        />
        <FormControlInput
          required
          label="Contract Duration (Months)"
          isNumeric
          name="duration"
          onChange={onChange}
          value={duration}
          isValidated={message.show && !(duration || duration === 0)}
        />
        <View style={commonFormStyle(theme).inLineFormContainer}>
          <FormControlInput
            required
            label="Renewal Duration (Months)"
            isNumeric
            name="renewal"
            onChange={onChange}
            value={renewal}
            isValidated={message.show && !(renewal || renewal === 0)}
          />
          <View style={{ marginLeft: 24, marginBottom: 10 }}>
            <FormCheckBox
              label={"Auto-Renewal"}
              name="autorenewal"
              value={autorenewal}
              onChange={onChange}
            />
          </View>
        </View>
      </View>
      <MessageValidation {...message} />
      <View style={commonFormStyle(theme).tableFormFieldContainer}>
        <ContractManagerDealTable
          requiredField={formData?.contracttypeid}
          gotToDealPage={gotToDealPage}
          getRecordData={getDealRecordData}
        />
      </View>
      <View style={commonFormStyle(theme).tableFormFieldContainer}>
        <AdvancesTable
          advanceFor={ADVANCES_TYPES.CONTRACT}
          getAdvanceData={getAdvanceData}
          advancesData={formData.advance_data}
        />
      </View>
      <View style={commonFormStyle(theme).tableFormFieldContainer}>
        <ExpensesTable
          expenseFor={ADVANCES_TYPES.CONTRACT}
          getExpenseData={getExpenseData}
          expensesData={formData.expense_data}
        />
      </View>
      <View style={commonFormStyle(theme).tableFormFieldContainer}>
        <PDFTable />
      </View>
      <View style={commonFormStyle(theme).formContainer}>
        <View style={commonFormStyle(theme).actionBtnContainer}>
          <CustomButton
            onPress={onSubmit}
            text={BUTTON_TEXTS.SUBMIT}
            cutomButtonStyle={commonFormStyle(theme).submitBtn}
          />
          <CustomButton
            text={BUTTON_TEXTS.CANCEL}
            onPress={openCancelConfirmationWindow}
            cutomButtonStyle={commonFormStyle(theme).backBtn}
          />
        </View>
      </View>
      <CustomModal
        visible={showCancelConfirmation}
        hideModal={closeCancelConfirmationWindow}
      >
        <ConfirmationWindow
          message={CANCEL_CONTRACT}
          handleNoOnClick={closeCancelConfirmationWindow}
          handleYesOnClick={gotToLandingPage}
        />
      </CustomModal>
      <CustomModal
        title={`Confirm`}
        visible={showEditConfirmation}
        hideModal={closeShowEditConfirmationWindow}
      >
        <ConfirmationWindow
          message={`${CONDFIRM_EDIT_MESSAGE}`}
          handleNoOnClick={closeShowEditConfirmationWindow}
          handleYesOnClick={onSubmit}
        />
      </CustomModal>
    </WebContentContainerWrapper>
  );
};

export default withTheme(ContractManagerForm);
