import React, { useContext, useEffect, useState, useCallback, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import MultiSelectFilter from '../Reports/CustomReportFilter/MultiSelectFilter';
import { config } from '../InvoicesData/invoice.config';
import { CommonDataTable, commonStatisticButtonStyle, CustomButton, Loader } from '../../../components';
import { ADD_INVOICE_TO_PAYMENT_URL, GET_ALL_REPORT_VERSIONS_URL, GET_SERVICES_URL, INVOICES_URL } from '../../../constants/GlobalUrls';
import useAxios from '../../../hooks/useAxios';
import AuthContextAPI from '../../../context/AuthContextAPI';
import axios from 'axios';
import { checkNetworkReachable } from '../../../utils/checkNetworkReachable';
import { BUTTON_TEXTS, DEFAULT_ERROR_MSG } from '../../../constants/GlobalVariables';
import { Checkbox, Searchbar, useTheme } from 'react-native-paper';
import { commonstyle } from '../Accounts/AccountEditPage/commonstyle';
import SecondaryHeaderTextSemiBold from '../../../components/CustomText/SecondaryHeaderTextSemiBold';
import { formatCurrency } from '../../../utils/formatCurrency';
import { TABLENAMES } from './payment.config';

const AddInvoiceToPaymentPopUp = ({ selectedRowData,
    onClickCloseShowInvoice,
    onSubmitSuccessful,
    tableData,
    setTableData,
    selectedRows,
    setSelectedRows,
    activeTab,
    relatedInvoicesIDs,
    relatedPayments
}) => {

    let theme = useTheme()

    // api hook
    let api = useAxios();

    const { logoutUser } = useContext(AuthContextAPI);
    const { linked_invoices_ids, data } = tableData

    // cancel token used to cancel the call if component is unmounted or need to programmatically cancel the request
    const cancelTokenSource = axios.CancelToken.source();

    const [loading, setLoading] = useState(true);

    const [options, setOptions] = useState({
        client_id: [],
        clientOptions: [],

        service_id: [],
        serviceOptions: [],

        report_version_id: [],
        reportVersionOptions: [],
    });

    const [searchQuery, setSearchQuery] = useState("");
    const onChangeSearch = (query) => setSearchQuery(query);

    useEffect(() => {
        let isMoulded = true
        if (isMoulded) {
            getAllOptions();
        }
        return () => {
            isMoulded = false
            cancelTokenSource.cancel();
        };
    }, []);

    useEffect(() => {
        let isMoulded = true
        if (isMoulded) {
            getInvoiceData();
        }
        return () => {
            isMoulded = false
            cancelTokenSource.cancel();
        };
    }, []);

    const {
        client_id,
        clientOptions,
        service_id,
        serviceOptions,
        report_version_id,
        reportVersionOptions,
    } = options


    const getAllOptions = async () => {
        setLoading(true)
        let endpoints = [
            { url: GET_ALL_REPORT_VERSIONS_URL, method: "get" },
            { url: GET_SERVICES_URL, method: "get" },
        ];
        await axios
            .all(
                endpoints.map((endpoint) => {
                    if (endpoint.method === "get") {
                        return api.get(endpoint.url, {
                            cancelToken: cancelTokenSource.token,
                        });
                    } else {
                        return api.post(endpoint.url, {
                            cancelToken: cancelTokenSource.token,
                        });
                    }
                })
            )
            .then(
                axios.spread(
                    (
                        { data: reportVersionOptions },
                        { data: serviceOptions }) => {
                        let tempData = {
                            ...options,
                            reportVersionOptions,
                            serviceOptions
                        }
                        setOptions(tempData)
                    }
                )
            )
            .catch((error) => {
                checkNetworkReachable();
                if (error?.response?.status === 401) {
                    logoutUser();
                } else if (!axios.isCancel(error) && error?.response) {
                    alert(DEFAULT_ERROR_MSG);
                }
            });
    };

    const getInvoiceData = async () => {

        setLoading(true);

        const handleSuccessResponse = (res) => {
            setTableData(res)
        };

        await api
            .post(
                INVOICES_URL,
                {
                    report_version_id: report_version_id[0],
                    client_id: relatedPayments.length > 0 && relatedPayments[0].client_id,
                    service_id: service_id[0],
                    not_linked_to_payment: true,
                },
                {
                    cancelToken: cancelTokenSource.token,
                }
            )
            .then((res) => {
                handleSuccessResponse(res.data);
            })
            .catch((error) => {
                checkNetworkReachable();
                setLoading(false);

                if (error?.response?.status === 401) {
                    logoutUser();
                } else if (!axios.isCancel(error) && error?.response) {
                    alert(DEFAULT_ERROR_MSG);
                }
            });
        setLoading(false);
    };

    const onChangeMultiSelectFilter = (value) => {
        let tempValue = { ...options, ...value };
        setOptions(tempValue);
        setTableData({ linked_invoices_ids: [], data: [] })
    };

    const onPressUpdateSelectedRow = (id) => {
        let tempData = [...selectedRows]
        const index = tempData.indexOf(id);
        if (index === -1) {
            tempData.push(id);  // Item not in array, so add it
        } else {
            tempData.splice(index, 1);  // Item in array, so remove it
        }
        setSelectedRows(tempData)
    };

    const onClickAddInvoicesToPayments = async () => {
        setLoading(true);

        const handleSuccessResponse = (res) => {
            alert(res.msg);
            onSubmitSuccessful();
        };

        await api
            .post(
                `${ADD_INVOICE_TO_PAYMENT_URL}${selectedRowData?.id}`,
                {
                    invoice_id: selectedRows,
                    merged: activeTab === TABLENAMES.mergedTable
                },
                {
                    cancelToken: cancelTokenSource.token,
                }
            )
            .then((res) => {
                handleSuccessResponse(res.data);
            })
            .catch((error) => {
                checkNetworkReachable();
                setLoading(false);

                if (error?.response?.status === 401) {
                    logoutUser();
                } else if (!axios.isCancel(error) && error?.response) {
                    alert(DEFAULT_ERROR_MSG);
                }
            });
        setLoading(false);
    }

    const getTotalForTheColumn = useCallback(
        (columnKey = "", jsonData = []) => {
            return jsonData.reduce((acc, o) => {
                const value = parseFloat(o[columnKey]);
                return acc + (isNaN(value) ? 0 : value);
            }, 0);
        },
        []
    );


    const getTotalForSelectedInvoices = useCallback(
        (columnKey = "", jsonData = [], filterDataByIDs = []) => {
            let total = 0;
            if (filterDataByIDs.length > 0) {
                jsonData = jsonData.filter(i => filterDataByIDs.includes(i.id));
                return getTotalForTheColumn(columnKey, jsonData);
            }
            return total;
        },
        []
    );

    // Memoize the results
    const totalForTheColumn = useMemo(() => {
        return getTotalForTheColumn("amountsubmitted", relatedPayments);
    }, [relatedPayments]);

    const totalForSelectedInvoices = useMemo(() => {
        return getTotalForSelectedInvoices("total", tableData.data, [...relatedInvoicesIDs, ...selectedRows]);
    }, [tableData.data, relatedInvoicesIDs, selectedRows]);

    // Memoize the difference
    const difference = useMemo(() => {
        return totalForTheColumn - totalForSelectedInvoices;
    }, [totalForTheColumn, totalForSelectedInvoices]);


    let columns = [
        {
            key: "id",
            title: "ID",
            dataIndex: "id",
            sort: true,
            search: true,
            width: 1,
            // bold: true,
        },
        {
            key: "name",
            title: "Name",
            dataIndex: "name",
            sort: true,
            search: true,
            width: 3.5,
            // bold: true,
        },
        {
            key: "clientid",
            title: "Client ID",
            dataIndex: "clientid",
            sort: true,
            width: 1.1,
        },
        {
            key: "client_name",
            title: "Client Name",
            dataIndex: "client_name",
            sort: true,
            width: 3.5,
        },
        {
            key: "service",
            title: "Service",
            dataIndex: "service",
            sort: true,
            filter: true,
            width: 2.5,
        },
        {
            key: "from_field",
            title: "From",
            dataIndex: "from_field",
            sort: true,
            filter: true,
            width: 1,
        },
        {
            key: "to",
            title: "To",
            dataIndex: "to",
            sort: true,
            filter: true,
            width: 1,
        },
        {
            key: "currency",
            title: "Currency",
            dataIndex: "currency",
            sort: true,
            filter: true,
            width: 1.5,
        },
        {
            key: "gross",
            title: "Gross",
            dataIndex: "gross",
            sort: true,
            width: 2.5,
            render: (currency) => {
                return formatCurrency(currency);
            },
        },
        {
            key: "net",
            title: "Net",
            dataIndex: "net",
            sort: true,
            width: 2.5,
            render: (currency) => {
                return formatCurrency(currency);
            },
        },
        {
            key: "adj",
            title: "Adj",
            dataIndex: "adj",
            sort: true,
            width: 2.5,
            render: (currency) => {
                return formatCurrency(currency);
            },
        },
        {
            key: "total",
            title: "Total",
            dataIndex: "total",
            sort: true,
            width: 2.5,
            render: (currency) => {
                return formatCurrency(currency);
            },
        },
        {
            key: "paymentdifference",
            title: "Payment Difference",
            dataIndex: "paymentdifference",
            sort: true,
            width: 2.5,
            render: (currency) => {
                return formatCurrency(currency);
            },
        },
        {
            key: "invoice_type",
            title: "Type",
            dataIndex: "invoice_type",
            sort: true,
            filter: true,
            width: 1.5,
        },
        {
            key: "report_version",
            title: "Report Version",
            dataIndex: "report_version",
            sort: true,
            filter: true,
            width: 2.2,
        },
        {
            key: "id",
            title: "Add",
            dataIndex: "id",
            width: 0.8,
            customToolTip: (id) => {
                const isSelected = [...linked_invoices_ids, ...selectedRows].includes(id);
                return isSelected ? "Selected" : "Not Selected";
            },
            center: true,
            render: (id) => {
                const isLinkedToClient = linked_invoices_ids.includes(id); // Determines if the row is linked
                const isLinkedToPayment = relatedInvoicesIDs.includes(id);
                const isSelected = [...linked_invoices_ids, ...selectedRows].includes(id); // Determines if the row is selected

                // Set checkbox color based on the state
                const checkboxColor = isLinkedToPayment
                    ? theme.colors.checkBoxdisabledColor // Selected color
                    : isLinkedToClient
                        ? theme.colors.disabled // Disabled color
                        : theme.colors.primary; // Default color

                return (
                    <Checkbox
                        color={checkboxColor} // Apply dynamic color
                        status={isSelected ? "checked" : "unchecked"} // Set the checkbox status
                        onPress={() => {
                            if (!isLinkedToClient) onPressUpdateSelectedRow(id); // Trigger action only if not linked
                        }}
                    />
                );
            },
        }

    ];

    return (
        <View style={styles.containerWraper}>
            <Loader loading={loading} />
            <View style={styles.filtersContainer}>
                <MultiSelectFilter
                    disabled
                    showEraser
                    optionName="client_name"
                    optionValue="client_id"
                    multipleSelect={false}
                    name={config.CLIENT_KEY}
                    title={config.CLIENT_NAME}
                    placeholdertext={`${config.CLIENTS_TITLE}: ${relatedPayments.length > 0 && relatedPayments[0].client_name}`}
                    value={client_id}
                    options={clientOptions}
                    onChange={onChangeMultiSelectFilter}
                />

                <MultiSelectFilter
                    showEraser
                    optionName="name"
                    optionValue="id"
                    multipleSelect={false}
                    name={config.SERVICE_KEY}
                    title={config.SERVICE_NAME}
                    placeholdertext={config.SERVICE_TITLE}
                    value={service_id}
                    options={serviceOptions}
                    onChange={onChangeMultiSelectFilter}
                />
                <MultiSelectFilter
                    showEraser
                    optionName="name"
                    optionValue="id"
                    multipleSelect={false}
                    name={config.REPORT_VERSION_KEY}
                    title={config.REPORT_VERSION_NAME}
                    placeholdertext={config.REPORT_VERSION_TITLE}
                    value={report_version_id}
                    options={reportVersionOptions}
                    onChange={onChangeMultiSelectFilter}
                />
            </View>
            <CustomButton
                text={"Search"}
                onPress={getInvoiceData}
            />
            <View>
                <View style={commonstyle(theme).searchTableWrapper}>
                    <Searchbar
                        placeholder={config.TABLE_INVOICE_TO_PAYMENT_SEARCH_PLACEHOLDER}
                        onChangeText={onChangeSearch}
                        value={searchQuery}
                        style={commonStatisticButtonStyle(theme, 700).searchBarWithoutMarginTop}
                        inputStyle={commonStatisticButtonStyle(theme).searchBarInput}
                    />
                    <View style={styles.verticalContainerWithGap}>
                        <SecondaryHeaderTextSemiBold
                            text={`Payments: ${formatCurrency(totalForTheColumn)}`}
                        />
                        <SecondaryHeaderTextSemiBold
                            text={`Invoices: ${formatCurrency(totalForSelectedInvoices)}`}
                        />
                        <SecondaryHeaderTextSemiBold
                            text={`Difference: ${formatCurrency(difference)}`}
                        />
                        <SecondaryHeaderTextSemiBold
                            text={`Selected: ${selectedRows.length}/${tableData.data.length}`}
                        />
                    </View>

                </View>
                <CommonDataTable
                    canFilterColumns
                    columns={columns}
                    data={data}
                    resetPage={searchQuery}
                    searchQuery={searchQuery}
                    tableMaxHeight={550}
                />
            </View>
            <View style={styles.verticalContainerWithGap}>
                <CustomButton
                    disabled={selectedRows.length === 0}
                    cutomButtonStyle={{ flex: 1 }}
                    text={BUTTON_TEXTS.SUBMIT}
                    onPress={onClickAddInvoicesToPayments}
                />
                <CustomButton
                    cutomButtonStyle={{
                        flex: 1, backgroundColor: theme.colors.avatarBackground,
                        borderColor: theme.colors.avatarBackground,
                    }}
                    text={BUTTON_TEXTS.CANCEL}
                    onPress={onClickCloseShowInvoice}
                />
            </View>
        </View>
    );
}


const styles = StyleSheet.create({
    containerWraper: { gap: 20 },
    filtersContainer: {
        flexDirection: "row",
        gap: 10,
        flex: 1,
        justifyContent: "space-between",
    },
    headerContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    clearBtnTextContainer: { flexDirection: "row", gap: 5, alignItems: "center" },
    verticalContainerWithGap: { flexDirection: "row", gap: 20 },
});

export default AddInvoiceToPaymentPopUp;
