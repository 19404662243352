export const BUTTON_TYPE = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  LINK: "link",
};

export const ENVIRONMENT = {
  develop: "Dev",
  prod: "Prod",
};

// localStorage variables
export const LOGIN_AUTH_ACCESS_TOKEN = "@authTokens";
export const USER_INFORMATION = "@userInformation";

// API methods
export const POST_METHOD = "POST";
export const GET_METHOD = "GET";
export const PUT_METHOD = "PUT";

// Toast Message types
export const MESSAGE_TYPE = {
  success: "success",
  error: "error",
  warning: "warning",
  info: "info",
};

// Default error message
export const DEFAULT_ERROR_MSG = "Something went wrong";

// Default required field validation message
export const DEFAULT_FIELD_VALIDATION_MESSAGE = "Please fill in all data!";
export const DEFAULT_REQUIRED_VALIDATION_MESSAGE =
  "Please fill all the required fields!";
export const INVALID_EMAIL_VALIDATION_MESSAGE = "Invalid email address!";

// Invalid email message
export const INVALID_EMAIL_MSG = "Invalid email format.";

// Invalid phone number message
export const INVALID_PHONE_NUMBER_MSG = "Invalid phone number format.";

// Form required field message
export const FORM_REQUIRED_FIELD_MSG = "This field cannot be empty!";

// Password do not match
export const CHANGE_PASSWORD_DONT_MATCH_MSG =
  "New and confirm passwords don't match.";

export const NEW_PASSWORD_DONT_MATCH_MSG = "Passwords don't match.";

// Information description text for form screens
export const INFORMATION_TEXT = {
  signUp:
    "We want to learn about you, please answer a few questions so the right person from our team can contact you.",
  forgotPassword: "Enter your email to receive a password reset link.",
  newPassword:
    "Create a new strong password! Use a combination of at least eight letters, numbers, and symbols.",
  contactSupport:
    "Here you can send a ticket to IDJWallet support team. We answer within 24 hours.",
  changePassword:
    "Create a new strong password! Use a combination of at least eight letters, numbers, and symbols.",
  passwordResetSuccessMsg:
    "We sent a password reset link to your email. Please check your inbox.",
  settingPageTile:
    "Here you can change your profile picture, turn notifications on and off and change mode between dark and light.",
};

// Default date label and config
export const locale = "us";
export const dateConfig = {
  save: "Save",
  selectSingle: "Select date",
  selectMultiple: "Select dates",
  selectRange: "Select period",
  notAccordingToDateFormat: (inputFormat) =>
    `Date format must be ${inputFormat}`,
  mustBeHigherThan: "Must be later then",
  mustBeLowerThan: "Must be earlier then",
  mustBeBetween: "Must be between",
  dateIsDisabled: "Day is not allowed",
};

// Action buttons text
export const BUTTON_TEXTS = {
  SAVE: "Save",
  SUBMIT: "Submit",
  BACK: "Back",
  CANCEL: "Cancel",
  NEXT: "Next",
  YES: "Yes",
  NO: "No",
  UPLOAD: "Upload",
  BACK_TO_MISSING_DSP_DEALS: "Back To Selection",
  DOWNLOAD_CSV: "Download CSV",
  IMPORT: "Import",
  DOWNLOAD_NOT_MERGED_CSV: "Download Not Merged CSV",
  DOWNLOAD_MERGED_CSV: "Download Merged CSV",
  MERGE: "Merge"
};

//Action buttons icon types
export const ACTION_BUTTON_TYPE = {
  EDIT: "mode-edit",
  DELETE: "delete-outline",
  DOWNLOAD: "file-download",
  UPLOAD: "file-upload",
  ADD: "plus",
  REMOVE: "minus",
  REVENUE: "hand-holding-usd",
  STATEMENT: "credit-card",
  ICON: "icon",
  LINK: "link",
  EXISTING: "existing",
  ASSIGN: "post-add",
  CLOUD_DOWNLOAD: "file-csv",
  DOWNLOAD_EXCEL: "file-excel",
  REPORT_STATUS: "report-status",
  DOWNLOAD_PDF: "file-pdf",
  FILE_SEARCH: "file-search",
  VIEW: "eye",
  VIEW_MORE: "more-horizontal"
};

// Confirmation messages
export const CANCEL_CONTRACT =
  "Are you sure you want to Cancel without saving this contract?";
export const CONFIRM_REMOVE_DEAL = "Are you sure you want to delete this Deal?";
export const CONDFIRM_EDIT_MESSAGE =
  "Data changed in the meantime, are you sure you want to replace them?";

//Common button text
export const DOWNLOAD_CSV_TEXT = "Download CSV";
export const DOWNLOAD_XLS_TEXT = "Download XLS";
export const DOWNLOAD_PBIT_CSV = "Download PBIT & CSV";
