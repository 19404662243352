import {
  Accounts,
  AdminPortal,
  Advances,
  ClientManager,
  ContractManager,
  Deals,
} from "../../../screens/WebDashboardPages";
import Adjustments from "../../../screens/WebDashboardPages/Adjustments/Adjustments";
import CatalogueConsumer from "../../../screens/WebDashboardPages/Catalogue/CatalogueConsumer";
import EmployeeAccounts from "../../../screens/WebDashboardPages/EmployeeAccounts/EmployeeAccounts";
import Expenses from "../../../screens/WebDashboardPages/Expenses/Expenses";
import InvoicesData from "../../../screens/WebDashboardPages/InvoicesData/InvoicesData";
import MissingDspLandingPage from "../../../screens/WebDashboardPages/MissingDspDeals/MissingDspLandingPage";
import OverlappingDspDeals from "../../../screens/WebDashboardPages/OverlappingDspDeals/OverlappingDspDeals";
import PaymentsConsumer from "../../../screens/WebDashboardPages/Payments/PaymentsConsumer";
import MergePaymentsConsumer from "../../../screens/WebDashboardPages/MergePayments/MergePaymentsConsumer";
import ReportVersions from "../../../screens/WebDashboardPages/ReportVersions/ReportVersions";
import Reports from "../../../screens/WebDashboardPages/Reports/Reports";
import RoyaltiesConsumer from "../../../screens/WebDashboardPages/Royalties/RoyaltiesConsumer";
import ImportPaymentsConsumer from '../../../screens/WebDashboardPages/ImportPayments/ImportPaymentsConsumer';

export default {
  ADMIN_PORTAL: {
    name: "admin_portal",
    title: "IDJCore",
    component: AdminPortal,
    drawerItemStyle: { height: 0 },
    inProgress: false,
  },
  ACCOUNTS: {
    name: "accounts",
    title: "User Accounts",
    component: Accounts,
    drawerItemStyle: {},
    inProgress: false,
  },
  EMPLOYEE_ACCOUNTS: {
    name: "employee_accounts",
    title: "Employee Accounts",
    component: EmployeeAccounts,
    drawerItemStyle: {},
    inProgress: false,
  },
  CLIENT_MANAGER: {
    name: "clients",
    title: "Clients",
    component: ClientManager,
    drawerItemStyle: {},
    inProgress: false,
  },
  CONTRACT_MANAGER: {
    name: "contracts",
    title: "Contracts",
    component: ContractManager,
    drawerItemStyle: {},
    inProgress: false,
  },
  DEALS: {
    name: "deals",
    title: "Deals",
    component: Deals,
    drawerItemStyle: {},
    inProgress: false,
  },
  MISSING_DSP_DEALS: {
    name: "missing_deals",
    title: "Missing Deals",
    component: MissingDspLandingPage,
    drawerItemStyle: {},
    inProgress: false,
  },
  OVERLAPPING_DSP_DEALS: {
    name: "conflicting_deals",
    title: "Conflicting Deals",
    component: OverlappingDspDeals,
    drawerItemStyle: {},
    inProgress: false,
  },
  ROYALTIES: {
    name: "royalties",
    title: "Royalties",
    component: RoyaltiesConsumer,
    drawerItemStyle: {},
    inProgress: false,
  },
  REPORTS: {
    name: "reports",
    title: "Reports",
    component: Reports,
    drawerItemStyle: {},
    inProgress: false,
  },
  INVOICESDATA: {
    name: "invoicesData",
    title: "Invoices",
    component: InvoicesData,
    drawerItemStyle: {},
    inProgress: false,
  },
  ADJUSTMENTS: {
    name: "adjustments",
    title: "Adjustments",
    component: Adjustments,
    drawerItemStyle: {},
    inProgress: true,
  },
  ADVANCES: {
    name: "advances",
    title: "Advances",
    component: Advances,
    drawerItemStyle: {},
    inProgress: false,
  },
  EXPENSES: {
    name: "expenses",
    title: "Expenses",
    component: Expenses,
    drawerItemStyle: {},
    inProgress: false,
  },
  REPORT_VERSION: {
    name: "reportVersion",
    title: "Report Versions",
    component: ReportVersions,
    drawerItemStyle: {},
    inProgress: false,
  },
   IMPORT_PAYMENTS: {
    name: "importPayments",
    title: "Import Payments",
    component: ImportPaymentsConsumer,
    drawerItemStyle: {},
    inProgress: false,
  },
  MERGE_PAYMENTS: {
    name: "merge_payments",
    title: "Merge Payments",
    component: MergePaymentsConsumer,
    drawerItemStyle: {},
    inProgress: false,
  },
  PAYMENTS: {
    name: "payments",
    title: "Payments",
    component: PaymentsConsumer,
    drawerItemStyle: {},
    inProgress: false,
  },
  CATALOGUE: {
    name: "catalogue",
    title: "Catalogue",
    component: CatalogueConsumer,
    drawerItemStyle: {},
    inProgress: false,
  },
  // INVOICESAUTOMATED: {
  //   name: "invoicesAutomation",
  //   title: "Invoices / Automation",
  //   component: ContractManager,
  //   drawerItemStyle: {  },
  //   inProgress: true,
  // },
};
