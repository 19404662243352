import { createContext, useContext, useEffect, useReducer } from "react";
import { STATISTICS_DATA } from '../mergePayment.config';
import AuthContextAPI from "../../../../context/AuthContextAPI";
import axios from "axios";
import { initialState, mergePaymentsDataReducer } from "./mergePaymentsReducer";
import useAxios from "../../../../hooks/useAxios";
import { checkNetworkReachable } from "../../../../utils/checkNetworkReachable";
import { DEFAULT_ERROR_MSG } from "../../../../constants/GlobalVariables";
import { LOADER, UPDATE_MULTIPLE_DATA, RESET_FILTERS, REFRESH_TABLE, TOGGLE_SELECTION, CLEAR_SELECTION } from "./actions";
import { GET_NOT_MERGED_CLIENTS_FILTER_PAYMENTS_URL, GET_NOT_MERGED_YEARS_FILTER_URL, MERGE_PAYMENT_URL, GET_NOT_MERGED_PAYMENTS_URL, GET_NOT_MERGED_ALL_CURRENCY_URL } from "../../../../constants/GlobalUrls";


const MergePaymentsContextAPI = createContext();

export default MergePaymentsContextAPI;

export const MergePaymentsProvider = ({ children }) => {

    // api hook
    let api = useAxios();

    const { logoutUser } = useContext(AuthContextAPI);

    // cancel token used to cancel the call if component is unmounted or need to programmatically cancel the request
    const cancelTokenSource = axios.CancelToken.source();

    const [state, dispatch] = useReducer(mergePaymentsDataReducer, initialState);

    useEffect(() => {
        let isMoulded = true;
        if (isMoulded) {
            getAllOptions();
        }
        return () => {
            isMoulded = false;
            cancelTokenSource.cancel();
        };
    }, []);

    useEffect(() => {
        let isMoulded = true;
        if (isMoulded && state.refreshTable > 0) {
            getData();
        }
        return () => {
            isMoulded = false;
            cancelTokenSource.cancel();
        };
    }, [state.refreshTable]);


    const getAllOptions = async () => {
        dispatch({ type: LOADER, payload: true });
        let endpoints = [
            { url: GET_NOT_MERGED_CLIENTS_FILTER_PAYMENTS_URL, method: "post", payload: { status: STATISTICS_DATA.PAID } },
            { url: GET_NOT_MERGED_YEARS_FILTER_URL, method: "post", payload: { status: STATISTICS_DATA.PAID } },
            { url: GET_NOT_MERGED_ALL_CURRENCY_URL, method: "post", payload: { status: STATISTICS_DATA.PAID } },
        ];
        await axios
            .all(
                endpoints.map((endpoint) => {
                    if (endpoint.method === "get") {
                        return api.get(endpoint.url, {
                            cancelToken: cancelTokenSource.token,
                        });
                    } else {
                        return api.post(endpoint.url, endpoint.payload, {
                            cancelToken: cancelTokenSource.token,
                        });
                    }
                })
            )
            .then(
                axios.spread(
                    ({ data: clientOptions }, { data: yearOptions }, { data: currencyOptions }) => {
                        dispatch({
                            type: UPDATE_MULTIPLE_DATA,
                            payload: {
                                clientOptions,
                                yearOptions,
                                currencyOptions,
                                loading: false,
                            },
                        });
                    }
                )
            )
            .catch((error) => {
                checkNetworkReachable();
                if (error?.response?.status === 401) {
                    logoutUser();
                } else if (!axios.isCancel(error) && error?.response) {
                    alert(DEFAULT_ERROR_MSG);
                }
            });
        dispatch({ type: LOADER, payload: false });
    };

    const getData = async () => {
        dispatch({ type: LOADER, payload: true });

        clearSelection();

        const handleSuccessResponse = (res) => {
            dispatch({
                type: UPDATE_MULTIPLE_DATA,
                payload: { tableData: res, loading: false },
            });
        };

        await api
            .post(
                GET_NOT_MERGED_PAYMENTS_URL,
                {
                    client_id: state?.client_id[0],
                    reporting_year: state?.reporting_year,
                    currency_id: state?.currency_id[0],
                    status: STATISTICS_DATA.PAID,
                },
                {
                    cancelToken: cancelTokenSource.token,
                }
            )
            .then((res) => {
                handleSuccessResponse(res.data);
            })
            .catch((error) => {
                checkNetworkReachable();
                dispatch({ type: LOADER, payload: false });
                if (error?.response?.status === 401) {
                    logoutUser();
                } else if (!axios.isCancel(error) && error?.response) {
                    alert(DEFAULT_ERROR_MSG);
                }
            });
    };

    const toggleSelection = (id) => {
        dispatch({ type: TOGGLE_SELECTION, payload: { id } });
    };

    const clearSelection = () => {
        dispatch({ type: CLEAR_SELECTION });
    };

    const onClearAllFilter = () => {
        dispatch({
            type: RESET_FILTERS,
            payload: {},
        });
        dispatch({ type: REFRESH_TABLE, payload: true })
    };


    const submitMergePayments = async () => {
        const selectedPaymentIds = state.selectedItems;

        await api
            .post(MERGE_PAYMENT_URL, {
                payment_ids: selectedPaymentIds,
            })
            .then(() => {
                alert("Payments merged successfully.");
                onClearAllFilter()
            })
            .catch((error) => {
                checkNetworkReachable();
                if (error?.response?.status === 401) {
                    logoutUser();
                }
                else if (!axios.isCancel(error) && error?.response) {
                    alert(error?.response?.data?.msg || DEFAULT_ERROR_MSG);
                }
                dispatch({ type: LOADER, payload: false })
            })
    };

    let contextData = {
        state,
        dispatch,
        getData,
        toggleSelection,
        clearSelection,
        submitMergePayments
    };

    return (
        <MergePaymentsContextAPI.Provider value={contextData}>
            {children}
        </MergePaymentsContextAPI.Provider>
    );
}