import { StyleSheet, Text, View } from 'react-native'
import React, { useContext, useState } from 'react'
import { config, STATISTICS_DATA } from './mergePayment.config'
import { commonStatisButtonCustomStyle, commonStatisticButtonStyle, CustomButton, Statistics, WebContentContainerWrapper } from '../../../components'
import MergePaymentsContextAPI from './Reducer/MergePaymentsContextAPI'
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { BUTTON_TEXTS, BUTTON_TYPE, DEFAULT_ERROR_MSG } from '../../../constants/GlobalVariables'
import { customStyle } from '../../../components/CustomText/customStyle'
import { useTheme } from 'react-native-paper'
import MultiSelectFilter from '../Reports/CustomReportFilter/MultiSelectFilter'
import SingleSelectFilter from '../Reports/CustomReportFilter/SingleSelectFilter'
import GetDataFromFilters from './GetDataFromFilters'
import MergePaymentTable from './MergePaymentTable'
import { RESET_FILTERS, UPDATE_MULTIPLE_DATA, LOADER } from './Reducer/actions'
import { ConfirmationWindow, CustomModal } from '../../../components'
import { DOWNLOAD_NOT_MERGED_CSV_URL } from '../../../constants/GlobalUrls'
import axios from 'axios'
import useAxios from '../../../hooks/useAxios'
import { checkNetworkReachable } from '../../../utils/checkNetworkReachable'
import AuthContextAPI from '../../../context/AuthContextAPI'
let uuid = require("uuid");

const LandingPage = () => {
    let theme = useTheme();
    let {
        state: {
            loading,
            client_id,
            clientOptions,
            yearOptions,
            reporting_year,
            currency_id,
            currencyOptions,
            tableData,
            selectedItems
        },
        dispatch,
        clearSelection,
        submitMergePayments,
    } = useContext(MergePaymentsContextAPI);
    const [showConfirmationWindow, setShowConfirmationWindow] = useState(false);
    const selectedCount = selectedItems.length;

    // api hook
    let api = useAxios();

    const { logoutUser } = useContext(AuthContextAPI);

    const cancelTokenSource = axios.CancelToken.source();
    const onChangeYear = (name, value) => {
        let payload = {
            [name]: value,
            tableData: [],
            selectedItems: []
        };
        dispatch({ type: UPDATE_MULTIPLE_DATA, payload: payload });
    };

    const onChangeMultiSelectFilter = (value) => {
        let tempValue = { ...value, tableData: [], selectedItems: [] };
        dispatch({
            type: UPDATE_MULTIPLE_DATA,
            payload: tempValue,
        });
    };

    const onClearAllFilter = () => {
        dispatch({
            type: RESET_FILTERS,
            payload: {},
        });
        clearSelection();
    };

    const openConfirmationWindow = () => {
        setShowConfirmationWindow(true);
    };

    const closeConfirmationWindow = () => {
        setShowConfirmationWindow(false);
    };

    const handleYesOnClick = async () => {
        closeConfirmationWindow();
        dispatch({ type: LOADER, payload: true });
        setTimeout(() => {
            submitMergePayments();
        }, 500);
    };

    const onClickDownloadCSV = async () => {

        let endpoint = { url: DOWNLOAD_NOT_MERGED_CSV_URL, payload: { status: "Paid" } };

        dispatch({ type: LOADER, payload: true });

        await api
            .post(endpoint.url, endpoint.payload, {
                responseType: "blob",
                cancelToken: cancelTokenSource.token,
            })
            .then((response) => {
                //Create a Blob from the csv Stream
                const file = new Blob([response.data], { type: "text/csv" });
                //Build a URL from the file
                var csvURL = window.URL.createObjectURL(file);
                let tempLink = document.createElement("a");
                tempLink.href = csvURL;
                tempLink.setAttribute("download", `not_merged_paid_${uuid.v4()}.csv`);
                tempLink.click();
                dispatch({ type: LOADER, payload: false });
            })
            .catch((error) => {
                checkNetworkReachable();
                dispatch({ type: LOADER, payload: false });
                if (error?.response?.status === 401) {
                    logoutUser();
                } else if (!axios.isCancel(error) && error?.response) {
                    alert(DEFAULT_ERROR_MSG);
                }
            });
    };

    const extraHeaderComponent = (
        <View style={commonStatisticButtonStyle(theme).statisticContainer}>
            <Statistics label={STATISTICS_DATA.TOTAL} value={tableData.length || 0} />
            <Statistics
                label={STATISTICS_DATA.SELECTED_ROWS}
                value={selectedCount}
            />
            <View style={commonStatisButtonCustomStyle(theme).buttonContainer}>
                <CustomButton
                    disabled={selectedCount < 2}
                    text={BUTTON_TEXTS.MERGE}
                    cutomButtonStyle={commonStatisButtonCustomStyle(theme).downloadBtn}
                    textStyle={commonStatisButtonCustomStyle(theme).text}
                    onPress={openConfirmationWindow}
                />
                <CustomButton
                    text={BUTTON_TEXTS.DOWNLOAD_CSV}
                    cutomButtonStyle={commonStatisButtonCustomStyle(theme).downloadBtn}
                    textStyle={commonStatisButtonCustomStyle(theme).text}
                    onPress={onClickDownloadCSV}
                />
            </View>
            <CustomModal
                visible={showConfirmationWindow}
                hideModal={closeConfirmationWindow}
                title='Merge'
            >
                <ConfirmationWindow
                    message={`Are you sure you want to proceed with merging the selected ${selectedCount} payments?`}
                    handleNoOnClick={closeConfirmationWindow}
                    handleYesOnClick={handleYesOnClick}
                />
            </CustomModal>
        </View>
    );

    return (
        <WebContentContainerWrapper loaderTransparent loading={loading} title={config.TITLE}>
            <View style={styles.headerContainer}>
                <Text style={customStyle(theme).headerText20}>
                    Make your selection to generate a comparison
                </Text>
                <CustomButton
                    buttontype={BUTTON_TYPE.SECONDARY}
                    onPress={onClearAllFilter}
                    text={
                        <View style={styles.clearBtnTextContainer}>
                            <MaterialCommunityIcons
                                name="restart"
                                size={20}
                                color={theme.colors.black}
                            />
                            <Text>Restart</Text>
                        </View>
                    }
                />
            </View>
            <View style={styles.filtersContainer}>
                <MultiSelectFilter
                    showEraser
                    optionName="client_name"
                    optionValue="client_id"
                    multipleSelect={false}
                    name={config.CLIENT_KEY}
                    title={config.CLIENT_NAME}
                    placeholdertext={config.CLIENTS_TITLE}
                    value={client_id}
                    options={clientOptions}
                    onChange={onChangeMultiSelectFilter}
                />

                <SingleSelectFilter
                    showEraser
                    placeholdertext={config.YEAR_TITLE}
                    name={config.YEAR_NAME}
                    value={reporting_year}
                    options={yearOptions}
                    onChange={onChangeYear}
                />
                <MultiSelectFilter
                    showEraser
                    optionName={config.CURRENCY_NAME}
                    optionValue="currency_id"
                    multipleSelect={false}
                    name={config.CURRENCY_KEY}
                    title={config.CURRENCY_NAME}
                    placeholdertext={config.CURRENCY_TITLE}
                    value={currency_id}
                    options={currencyOptions}
                    onChange={onChangeMultiSelectFilter}
                />
            </View>
            <GetDataFromFilters />
            <View style={styles.statisticContainer}>
                {extraHeaderComponent}
            </View>
            <MergePaymentTable />
        </WebContentContainerWrapper>
    )
}

export default LandingPage

const styles = StyleSheet.create({
    filtersContainer: {
        flexDirection: "row",
        gap: 10,
        flex: 1,
        justifyContent: "space-between",
    },
    headerContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    clearBtnTextContainer: { flexDirection: "row", gap: 5, alignItems: "center" },
    statisticContainer: { marginTop: 15 },
});
