import React, { useCallback, useEffect, useState } from "react";
import {
  Dimensions,
  FlatList,
  Pressable,
  View,
  StyleSheet,
} from "react-native";
import { DataTable, Text, withTheme } from "react-native-paper";
import { ActionsButtonIcons, CustomModal, Loader } from "..";
import { commonDataTablestyle, tooltipStyle } from "./commonDataTablestyle";
import { ACTION_BUTTON_TYPE } from "../../constants/GlobalVariables";
import {
  AntDesign,
  Ionicons,
  MaterialCommunityIcons,
  FontAwesome,
} from "@expo/vector-icons";
import _ from "lodash";
import { Tooltip } from "react-hover-tooltip";
import FilterColumnPopUp from "./FilterColumnPopUp";
import CustomChip from "../CustomChip/CustomChip";
import { TouchableOpacity } from "react-native-gesture-handler";
import SecondaryHeaderTextSemiBold from "../CustomText/SecondaryHeaderTextSemiBold";
import { formatCurrency } from "../../utils/formatCurrency";
import { TABLE_ACTION } from "../../screens/WebDashboardPages/ContractManager/ContractManagerDealForm/ContractManagerDealForm.config";

const screen = Dimensions.get("window");
const { height } = screen;

const numberOfItemsPerPageList = [10, 20, 100, "ALL"];

const CommonDataTable = ({
  theme,
  columns = [],
  data = [],
  resetPage,
  loading = false,
  showPagination = true,
  showAddButton = false,
  handleAddBtnClick,
  showUploadButton,
  searchQuery = "",
  defaultPagePerRow = 10,
  mobileView = false,
  showHeader = true,
  disableBottomButton = true,
  canFilterColumns = false,
  showFooterTotal = false,
  tableMaxHeight = 400
}) => {
  const [selectedColumnForSort, setSelectedColumnForSort] = useState({
    direction: null,
    selectedColumn: null,
    isDate: false,
  });

  const [appliedColumnFilter, setAppliedColumnFilter] = useState({
    selectedColumnForFilter: null,
    columnsDetails: {},
    filtersApplied: {},
    displayName: {},
    listOfValuesForSelection: [],
    filterCount: {},
  });

  const [page, setPage] = useState(0);
  const [numberOfItemsPerPage, onItemsPerPageChange] = useState({
    pageSize: defaultPagePerRow,
    label: defaultPagePerRow,
  });

  const { direction, selectedColumn, isDate } = selectedColumnForSort;
  const {
    columnsDetails,
    filtersApplied,
    displayName,
    listOfValuesForSelection,
  } = appliedColumnFilter;

  useEffect(() => {
    let isMoulded = true;
    if (isMoulded) {
      if (page !== 0) {
        setPage(0);
      }

      if (!showPagination) {
        callBackOnItemsPerPageChange("ALL");
      }
    }
    return () => {
      isMoulded = false;
    };
  }, [resetPage, JSON.stringify(data)]);

  const callBackOnItemsPerPageChange = (pageSize) => {
    if (pageSize === "ALL") {
      onItemsPerPageChange({
        pageSize: data.length,
        label: pageSize,
      });
    } else {
      onItemsPerPageChange({
        pageSize: pageSize,
        label: pageSize,
      });
    }
  };

  const calculateRow = (tempData) => {
    let pageSize = numberOfItemsPerPage.pageSize;
    if (numberOfItemsPerPage.label === "ALL" && pageSize < tempData.length) {
      callBackOnItemsPerPageChange(numberOfItemsPerPage.label);
      pageSize = tempData.length;
    }
    const fromTemp = page * pageSize;
    const toTemp = Math.min((page + 1) * pageSize, tempData.length);
    const rowDataTemp =
      tempData.length > 0 ? tempData.slice(fromTemp, toTemp) : tempData;
    return rowDataTemp;
  };

  useEffect(() => {
    setPage(0);
  }, [numberOfItemsPerPage.pageSize]);

  const getTableHeight = () => {

    let calculatedHeight = height - tableMaxHeight

    if (numberOfItemsPerPage.pageSize === 10 && calculatedHeight > 530) {
      return 530;
    }
    return calculatedHeight
  };

  let minHeight = showPagination ? getTableHeight() : 238;
  let maxHeight = showPagination ? getTableHeight() : 238;

  const sortTable = (column, isDate) => {
    let newDirection = null;
    if (direction === "asc" && column === selectedColumn) {
      setSelectedColumnForSort({
        selectedColumn: "",
        direction: newDirection,
        isDate: false,
      });
    } else {
      if (column !== selectedColumn) {
        newDirection = "desc";
      } else {
        newDirection = direction === "desc" ? "asc" : "desc";
      }
      setSelectedColumnForSort({
        selectedColumn: column,
        direction: newDirection,
        isDate: isDate,
      });
    }
  };

  const filterSearchedValue = (tempArr) => {
    let searchQueryLowerCase = searchQuery.trim().toLocaleLowerCase();
    let tempColsToSearch = columns
      .map((col) => {
        if (col.search) {
          return col.dataIndex;
        }
      })
      .filter((x) => x != null);
    let filteredData = tempArr.filter((element) =>
      tempColsToSearch.some((key) =>
        String(element[key]).toLowerCase().includes(searchQueryLowerCase)
      )
    );
    return filteredData;
  };

  const applyColumnFilter = (tempArr) => {
    let finalData = [];
    let count = 0;
    Object.entries(columnsDetails).forEach(([key, val]) => {
      if (count === 0) {
        finalData = tempArr.filter((element) => {
          if (val.indexOf(element[key]) > -1) {
            return element;
          }
        });
      } else {
        finalData = finalData.filter((element) => {
          if (val.indexOf(element[key]) > -1) {
            return element;
          }
        });
      }
      count = count + 1;
    });
    return finalData;
  };

  const getColumnFilterOptions = (dataIndex) => {
    if (Object.keys(columnsDetails).length > 0) {
      let finalData = [];
      let count = 0;
      for (const [key, val] of Object.entries(columnsDetails)) {
        if (count === 0) {
          if (dataIndex === key) {
            finalData = [...data];
            break;
          } else {
            finalData = [...data].filter((element) => {
              if (val.indexOf(element[key]) > -1) {
                return element;
              }
            });
          }
        } else {
          if (dataIndex === key) {
            break;
          }
          finalData = finalData.filter((element) => {
            if (val.indexOf(element[key]) > -1) {
              return element;
            }
          });
        }

        count = count + 1;
      }
      return finalData;
    }
    return data;
  };

  const getData = useCallback(() => {
    if (data.length > 0) {
      let sortedData = [...data];
      if (selectedColumn) {
        if (isDate) {
          sortedData = _.orderBy(
            [...sortedData],
            function (o) {
              return new Date(o[selectedColumn]);
            },
            [direction]
          );
        } else {
          sortedData = _.orderBy(
            [...sortedData],
            [selectedColumn],
            [direction]
          );
        }
      }
      if (canFilterColumns && Object.keys(columnsDetails).length > 0) {
        sortedData = applyColumnFilter(sortedData);
      }
      if (searchQuery.length > 0) {
        sortedData = filterSearchedValue(sortedData);
      }
      return {
        lengthofData: sortedData.length,
        sortedData,
        finalData: calculateRow(sortedData),
      };
    }
    return {
      lengthofData: 0,
      sortedData: [],
      finalData: [],
    };
  }, [
    JSON.stringify(data),
    searchQuery,
    direction,
    selectedColumn,
    page,
    numberOfItemsPerPage.pageSize,
    JSON.stringify(columnsDetails),
  ]);

  const openFilterPopUp = (column) => {
    let filteredData = getColumnFilterOptions(column.dataIndex);
    let values = [
      ...new Set(
        [...filteredData].map((items) => {
          return items[column.dataIndex];
        })
      ),
    ];
    // Sort case-insensitively and put null/undefined at the beginning
    values = _(values)
      .sortBy((value) => {
        if (value === null || value === undefined || value === "") {
          return ""; // Null/undefined come first
        }
        if (typeof value === "number") {
          return value; // Sort numbers naturally
        }
        return String(value).toLowerCase(); // Sort strings case-insensitively
      })
      .value();

    let tempColumm = { ...column, optionsLength: values.length };
    const filterCount = filteredData.reduce((acc, report) => {
      acc[report[column.dataIndex]] = (acc[report[column.dataIndex]] || 0) + 1;
      return acc;
    }, {});
    setAppliedColumnFilter({
      ...appliedColumnFilter,
      selectedColumnForFilter: tempColumm,
      listOfValuesForSelection: values,
      filterCount: filterCount,
    });
  };

  const closeFilterColumnPopUp = () => {
    setAppliedColumnFilter({
      ...appliedColumnFilter,
      selectedColumnForFilter: null,
      listOfValuesForSelection: [],
      filterCount: {},
    });
  };

  const onClickApply = (values, col) => {
    let columnsDetailsTemp = { ...columnsDetails };
    let filtersAppliedTemp = { ...filtersApplied };
    let displayNameTemp = {
      ...displayName,
      [col.dataIndex]: col?.title,
    };
    if (
      values.length === listOfValuesForSelection.length &&
      col.dataIndex in columnsDetails
    ) {
      delete columnsDetailsTemp[col.dataIndex];
      delete filtersAppliedTemp[col.dataIndex];
    } else {
      columnsDetailsTemp = {
        ...columnsDetails,
        [col.dataIndex]: values,
      };
      filtersAppliedTemp = {
        ...filtersApplied,
        [col.dataIndex]: listOfValuesForSelection.length !== values.length,
      };
    }
    setAppliedColumnFilter({
      selectedColumnForFilter: null,
      listOfValuesForSelection: [],
      columnsDetails: columnsDetailsTemp,
      filtersApplied: filtersAppliedTemp,
      displayName: displayNameTemp,
      filterCount: {},
    });
    setPage(0);
  };

  const onPressRemoveFilter = (dataIndex) => {
    onClickApply([], { dataIndex: dataIndex, title: dataIndex });
    setPage(0);
  };

  const ClearAllFilters = () => {
    setAppliedColumnFilter({
      selectedColumnForFilter: null,
      columnsDetails: {},
      filtersApplied: {},
      displayName: {},
      listOfValuesForSelection: [],
      filterCount: {},
    });
    setPage(0);
  };

  let displaySelectedFilters = useCallback(() => {
    return (
      <View style={styles(theme).filterDisplayContainer}>
        <View style={styles(theme).filterChipsContainer}>
          <TouchableOpacity
            style={styles(theme).clearBtnContainer}
            onPress={() => {
              ClearAllFilters();
            }}
          >
            <View style={styles(theme).clearBtnLabelContainer}>
              <MaterialCommunityIcons
                name="filter-remove-outline"
                size={20}
                color={theme.colors.datatableTitleText}
              />
              <Text>Clear All</Text>
            </View>
          </TouchableOpacity>
          {Object.entries(columnsDetails).map(([key, val]) => {
            return (
              <CustomChip
                transparent
                shouldConfirm={false}
                key={key}
                label={
                  <View style={styles(theme).chipLabelContainer}>
                    <Text>{displayName[key]}: </Text>
                    {
                      <View
                        style={styles(theme).chipSelectedFilterCountContainer}
                      >
                        <Text
                          style={styles(theme).chipSelectedFilterCountLabel}
                        >
                          {val.length}
                        </Text>
                      </View>
                    }
                  </View>
                }
                value={key}
                onClose={onPressRemoveFilter}
              />
            );
          })}
        </View>
        <View>
          <SecondaryHeaderTextSemiBold
            text={`Filtered Rows: ${lengthofData}`}
          />
        </View>
      </View>
    );
  }, [JSON.stringify(filtersApplied), JSON.stringify(columnsDetails)]);

  let { lengthofData, sortedData, finalData } = getData();

  const getTotalForTheColumn = useCallback(
    (columnKey) => {
      let total = 0;
      total = sortedData.reduce((acc, o) => acc + parseFloat(o[columnKey]), 0);

      return formatCurrency(total);
    },
    [sortedData]
  );

  return (
    <>
      <Loader loading={loading} />
      {canFilterColumns &&
        Object.keys(columnsDetails).length > 0 &&
        displaySelectedFilters()}
      <FlatList
        style={{ maxHeight: maxHeight, minHeight: minHeight }}
        stickyHeaderIndices={[0]}
        ListHeaderComponent={
          <View style={commonDataTablestyle(theme).tableHeader}>
            {columns.length > 0 &&
              columns.map((column, index) => {
                return (
                  <Pressable
                    key={index}
                    disabled={!column?.sort}
                    style={
                      commonDataTablestyle(
                        theme,
                        column?.width,
                        null,
                        null,
                        null,
                        column?.center,
                        column?.left
                      ).columnWidth
                    }
                    onPress={() =>
                      column?.sort &&
                      sortTable(column.key, column?.isDate || false)
                    }
                  >
                    <Text
                      numberOfLines={1}
                      style={
                        commonDataTablestyle(
                          theme,
                          column?.width,
                          null,
                          null,
                          null,
                          column?.center,
                          column?.left
                        ).datatableTitle
                      }
                    >
                      {selectedColumn === column?.sortKey ||
                        selectedColumn === column.key ? (
                        <AntDesign
                          style={styles(theme).sortIconStyle}
                          color={theme.colors.text}
                          name={direction === "desc" ? "arrowdown" : "arrowup"}
                        />
                      ) : (
                        ""
                      )}
                      <Tooltip
                        content={
                          <Text style={tooltipStyle(theme).textMessage}>
                            {column.title}
                          </Text>
                        }
                      >
                        {column.title}
                      </Tooltip>
                    </Text>
                    <View style={{ alignSelf: "flex-end" }}>
                      {canFilterColumns &&
                        column?.filter &&
                        (column?.dataIndex in columnsDetails &&
                          column?.dataIndex in filtersApplied &&
                          filtersApplied[column?.dataIndex] ? (
                          <FontAwesome
                            style={styles(theme).filterIconStyle}
                            onPress={() => {
                              openFilterPopUp(column);
                            }}
                            name="filter"
                            size={13}
                            color={theme.colors.primary}
                          />
                        ) : (
                          <Ionicons
                            style={styles(theme).filterIconStyle}
                            onPress={() => {
                              openFilterPopUp(column);
                            }}
                            name="filter-sharp"
                            size={13}
                            color={theme.colors.datatableTitleText}
                          />
                        ))}
                    </View>
                  </Pressable>
                );
              })}
          </View>
        }
        nestedScrollEnabled
        // ListEmptyComponent={<NoData />}
        keyExtractor={(item, index) => index}
        data={finalData}
        renderItem={({ item, i }) => (
          <DataTable.Row
            onPress={() => { }}
            key={i}
            style={{
              paddingHorizontal: 0,
              borderTopWidth: mobileView && !showHeader && i === 0 ? 1 : 0,
              borderColor: theme.colors.divider,
              // borderBottomWidth: mobileView
              //   ? i + 1 === rowData.length
              //     ? 0
              //     : 1
              //   : 1,
            }}
          >
            {columns.map((row, j) => {

              let backgroundColor = theme.colors.card;

              if (item?.action) {
                if (item?.action === TABLE_ACTION.delete) {
                  backgroundColor = theme.colors.errorSelectedTableBackgroundColor;
                } else if (item?.action === TABLE_ACTION.update) {
                  backgroundColor = theme.colors.editSelectedTableBackgroundColor;
                } else if (item?.action === TABLE_ACTION.add) {
                  backgroundColor = theme.colors.selectedTableBackgroundColor;
                }
              }

              return (
                <DataTable.Cell
                  key={j}
                  style={
                    commonDataTablestyle(
                      theme,
                      row?.width,
                      null,
                      null,
                      null,
                      row?.center,
                      row?.left,
                      null,
                      item?.existing,
                      backgroundColor
                    ).columnWidth
                  }
                >
                  {row?.hideToolTip ? (
                    <Text selectable>
                      {row.hasOwnProperty("render")
                        ? row.render(item[row.dataIndex], item)
                        : item[row.dataIndex]}
                    </Text>
                  ) : (
                    <Tooltip
                      content={
                        <Text style={tooltipStyle(theme).textMessage}>
                          {row.hasOwnProperty("customToolTip")
                            ? row.customToolTip(item[row.dataIndex], item)
                            : item[row.dataIndex]}
                        </Text>
                      }
                    >
                      <Text selectable>
                        {row.hasOwnProperty("render")
                          ? row.render(item[row.dataIndex], item)
                          : item[row.dataIndex]}
                      </Text>
                    </Tooltip>
                  )}
                </DataTable.Cell>
              );
            })}
          </DataTable.Row>
        )}
      />
      {!!(showAddButton || showUploadButton) && (
        <DataTable.Row style={{ paddingHorizontal: 0 }}>
          <DataTable.Cell>
            <ActionsButtonIcons
              buttonType={
                showAddButton
                  ? ACTION_BUTTON_TYPE.ADD
                  : ACTION_BUTTON_TYPE.UPLOAD
              }
              handleOnPress={handleAddBtnClick}
              active={disableBottomButton}
            />
          </DataTable.Cell>
        </DataTable.Row>
      )}
      {showFooterTotal && (
        <DataTable.Row style={{ paddingHorizontal: 0 }}>
          {columns.length > 0 &&
            columns.map((column, index) => {
              return (
                <DataTable.Cell
                  key={index}
                  style={
                    commonDataTablestyle(
                      theme,
                      column?.width,
                      null,
                      null,
                      null,
                      column?.center,
                      column?.left
                    ).columnWidth
                  }
                >
                  <Text
                    numberOfLines={1}
                    style={
                      commonDataTablestyle(
                        theme,
                        column?.width,
                        null,
                        null,
                        null,
                        column?.center,
                        column?.left
                      ).datatableFooterTotal
                    }
                  >
                    <Tooltip
                      content={
                        <Text style={tooltipStyle(theme).textMessage}>
                          {column.title}
                        </Text>
                      }
                    >
                      {index === 0 && "Total"}
                      {column?.total
                        ? getTotalForTheColumn(column.dataIndex)
                        : ""}
                    </Tooltip>
                  </Text>
                </DataTable.Cell>
              );
            })}
        </DataTable.Row>
      )}
      {!!showPagination && (
        <DataTable.Pagination
          style={{ backgroundColor: theme.colors.card }}
          page={page}
          numberOfPages={Math.ceil(
            Number(lengthofData) / numberOfItemsPerPage.pageSize
          )}
          onPageChange={(selectedPage) => setPage(selectedPage)}
          label={`${page + 1} - ${Math.ceil(
            Number(lengthofData) / numberOfItemsPerPage.pageSize
          )}`}
          showFastPaginationControls
          numberOfItemsPerPageList={numberOfItemsPerPageList}
          numberOfItemsPerPage={numberOfItemsPerPage.label}
          onItemsPerPageChange={callBackOnItemsPerPageChange}
          selectPageDropdownLabel={"Rows per page"}
        />
      )}
      <CustomModal
        visible={appliedColumnFilter.selectedColumnForFilter}
        hideModal={closeFilterColumnPopUp}
      >
        <FilterColumnPopUp
          data={appliedColumnFilter}
          onClickApply={onClickApply}
        />
      </CustomModal>
    </>
  );
};

export default withTheme(CommonDataTable);

const styles = (theme) =>
  StyleSheet.create({
    filterDisplayContainer: {
      flexDirection: "row",
      justifyContent: "space-between",
      flex: 1,
    },
    filterChipsContainer: {
      flexDirection: "row",
      gap: 4,
      flexWrap: "wrap",
      flex: 1,
    },
    clearBtnContainer: {
      borderWidth: 1,
      alignItems: "center",
      justifyContent: "center",
      width: "fit-content",
      padding: 5,
      paddingHorizontal: 10,
      borderRadius: 25,
      borderColor: theme.colors.primary,
    },
    clearBtnLabelContainer: {
      flexDirection: "row",
      gap: 4,
      alignItems: "center",
    },
    chipLabelContainer: { flexDirection: "row" },
    chipSelectedFilterCountContainer: {
      backgroundColor: theme.colors.primary,
      borderRadius: 25,
      paddingHorizontal: 5,
    },
    chipSelectedFilterCountLabel: { textAlign: 1 },
    filterIconStyle: { marginLeft: 4 },
    sortIconStyle: { marginRight: 4 },
  });
