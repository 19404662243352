import { View } from "react-native";
import React, { useContext, useState } from "react";
import { Searchbar, useTheme } from "react-native-paper";
import { commonstyle } from "../Accounts/AccountEditPage/commonstyle";
import {
    CommonDataTable,
    commonStatisticButtonStyle,
} from "../../../components";
import ImportPaymentsContextAPI from "./Reducer/ImportPaymentsContextAPI";
import { config } from "./importPayment.config";
import { formatCurrency } from "../../../utils/formatCurrency";
import dayjs from "dayjs";

const ImportPaymentTable = () => {
    let theme = useTheme();
    let {
        state: { tableData },
    } = useContext(ImportPaymentsContextAPI);

    const [searchQuery, setSearchQuery] = useState("");
    const onChangeSearch = (query) => setSearchQuery(query);

    let columns = [
        {
            key: "id",
            title: "ID",
            dataIndex: "id",
            sort: true,
            search: true,
            width: 0.8,
        },
        {
            key: "client_id",
            title: "Client ID",
            dataIndex: "client_id",
            sort: true,
            search: true,
            filter: true,
            width: 1.2,
        },
        {
            key: "client_name",
            title: "Client Name",
            dataIndex: "client_name",
            sort: true,
            search: true,
            filter: true,
            width: 2.2,
        },
        {
            key: "currency",
            title: "Currency",
            dataIndex: "currency",
            sort: true,
            filter: true,
            width: 1.3,
        },
        {
            key: "payment_currency",
            title: "Payment Currency",
            dataIndex: "payment_currency",
            sort: true,
            filter: true,
            width: 2.2,
        },
        {
            key: "date_requested",
            title: "Requested",
            dataIndex: "date_requested",
            sort: true,
            filter: true,
            width: 1.4,
            isDate: true,
            customToolTip: (date) => {
                return date ? dayjs(date).format("DD/MM/YYYY") : null
            },
            render: (date) => {
                return date ? dayjs(date).format("DD/MM/YYYY") : null
            },
        },
        {
            key: "remittance_date",
            title: "Remitted",
            dataIndex: "remittance_date",
            sort: true,
            filter: true,
            width: 1.4,
            isDate: true,
            customToolTip: (date) => {
                return date ? dayjs(date).format("DD/MM/YYYY") : null
            },
            render: (date) => {
                return date ? dayjs(date).format("DD/MM/YYYY") : null
            },
        },
        {
            key: "amountsubmitted",
            title: "Amount",
            dataIndex: "amountsubmitted",
            sort: true,
            width: 2.5,
            render: (currency) => {
                return formatCurrency(currency);
            },
        },
        {
            key: "type",
            title: "Type",
            dataIndex: "type",
            sort: true,
            filter: true,
            width: 4,
        },
        {
            key: "paid_via",
            title: "Paid Via",
            dataIndex: "paid_via",
            sort: true,
            filter: true,
            width: 2,
        },
        {
            key: "no_of_invoices",
            title: "# Invoices",
            dataIndex: "no_of_invoices",
            sort: true,
            filter: true,
            width: 1.5,
        },
        {
            key: "status",
            title: "Status",
            dataIndex: "status",
            sort: true,
            filter: true,
            width: 1.1,
        },
        {
            key: "approved_by",
            title: "Approved",
            dataIndex: "approved_by",
            sort: true,
            filter: true,
            width: 1.4,
        },
    ];

    return (
        <View>
            <View style={commonstyle(theme).searchTableWrapper}>
                <Searchbar
                    placeholder={config.TABLE_SEARCH_PLACEHOLDER}
                    onChangeText={onChangeSearch}
                    value={searchQuery}
                    style={commonStatisticButtonStyle(theme, 700).searchBar}
                    inputStyle={commonStatisticButtonStyle(theme).searchBarInput}
                />
            </View>
            <CommonDataTable
                canFilterColumns
                columns={columns}
                data={tableData}
                resetPage={searchQuery}
                searchQuery={searchQuery}
                tableMaxHeight={600}
            />
        </View>
    );
};

export default ImportPaymentTable;
