import { createContext, useContext, useEffect, useReducer } from "react";
import AuthContextAPI from "../../../../context/AuthContextAPI";
import axios from "axios";
import { initialMergedTableFilters, initialNotMergedTableFilters, initialState, mergedTableFiltersDataReducer, notMergedTableFiltersDataReducer, paymentDataReducer } from "./paymentReducer";
import useAxios from "../../../../hooks/useAxios";
import { checkNetworkReachable } from "../../../../utils/checkNetworkReachable";
import { DEFAULT_ERROR_MSG } from "../../../../constants/GlobalVariables";
import { LOADER, SELECTED_ROW, UPDATE_FILTER_DATA, UPDATE_MULTIPLE_DATA } from "./actions";
import { GET_NOT_MERGED_ALL_CURRENCY_URL, GET_NOT_MERGED_CLIENTS_FILTER_PAYMENTS_URL, GET_INVOICE_DETAILS_TABLE_FOR_PAYMENT_URL, GET_NOT_MERGED_YEARS_FILTER_URL, PAYMENT_URL, GET_MERGED_CLIENTS_FILTER_PAYMENTS_URL, GET_MERGED_ALL_CURRENCY_URL, GET_NOT_MERGED_PAYMENTS_URL, GET_MERGED_PAYMENTS_URL } from "../../../../constants/GlobalUrls";
import { config, TABLENAMES } from "../payment.config";

const PaymentContextAPI = createContext();

export default PaymentContextAPI;

export const PaymentProvider = ({ children, pageName = config.TITLE }) => {

    // api hook
    let api = useAxios();

    const { logoutUser } = useContext(AuthContextAPI);

    // cancel token used to cancel the call if component is unmounted or need to programmatically cancel the request
    const cancelTokenSource = axios.CancelToken.source();

    const [state, dispatch] = useReducer(paymentDataReducer, initialState);
    const [notMergedFilterState, notMergedFilterdispatch] = useReducer(notMergedTableFiltersDataReducer, initialNotMergedTableFilters);
    const [mergedFilterState, mergedFilterdispatch] = useReducer(mergedTableFiltersDataReducer, initialMergedTableFilters);

    useEffect(() => {
        let isMoulded = true;
        if (isMoulded && pageName === config.TITLE) {
            if (state?.activeTab === TABLENAMES.notMergedTable) {
                if (notMergedFilterState?.clientOptions.length === 0) {
                    getNotMergedFiltersAllOptions();
                }
            } else if (state?.activeTab === TABLENAMES.mergedTable) {
                if (mergedFilterState?.clientOptions.length === 0) {
                    getMergedFiltersAllOptions();
                }
            }
        }
        return () => {
            isMoulded = false;
            cancelTokenSource.cancel();
        };
    }, [state?.activeTab]);

    const getNotMergedFiltersAllOptions = async () => {
        dispatch({ type: LOADER, payload: true });
        let endpoints = [
            { url: GET_NOT_MERGED_CLIENTS_FILTER_PAYMENTS_URL, method: "post" },
            { url: GET_NOT_MERGED_YEARS_FILTER_URL, method: "post" },
            { url: GET_NOT_MERGED_ALL_CURRENCY_URL, method: "post" },
        ];
        await axios
            .all(
                endpoints.map((endpoint) => {
                    if (endpoint.method === "get") {
                        return api.get(endpoint.url, {
                            cancelToken: cancelTokenSource.token,
                        });
                    } else {
                        return api.post(endpoint.url, {
                            cancelToken: cancelTokenSource.token,
                        });
                    }
                })
            )
            .then(
                axios.spread(
                    ({ data: clientOptions }, { data: yearOptions }, { data: currencyOptions }) => {
                        notMergedFilterdispatch({
                            type: UPDATE_FILTER_DATA,
                            payload: {
                                clientOptions,
                                yearOptions,
                                currencyOptions,
                                loading: false,
                            },
                        });
                    }
                )
            )
            .catch((error) => {
                checkNetworkReachable();
                if (error?.response?.status === 401) {
                    logoutUser();
                } else if (!axios.isCancel(error) && error?.response) {
                    alert(DEFAULT_ERROR_MSG);
                }
            });
        dispatch({ type: LOADER, payload: false });
    };

    const getMergedFiltersAllOptions = async () => {
        dispatch({ type: LOADER, payload: true });
        let endpoints = [
            { url: GET_MERGED_CLIENTS_FILTER_PAYMENTS_URL, method: "get" },
            { url: GET_MERGED_ALL_CURRENCY_URL, method: "get" },
        ];
        await axios
            .all(
                endpoints.map((endpoint) => {
                    if (endpoint.method === "get") {
                        return api.get(endpoint.url, {
                            cancelToken: cancelTokenSource.token,
                        });
                    } else {
                        return api.post(endpoint.url, {
                            cancelToken: cancelTokenSource.token,
                        });
                    }
                })
            )
            .then(
                axios.spread(
                    ({ data: clientOptions }, { data: currencyOptions }) => {
                        mergedFilterdispatch({
                            type: UPDATE_FILTER_DATA,
                            payload: {
                                clientOptions,
                                currencyOptions,
                                loading: false,
                            },
                        });
                    }
                )
            )
            .catch((error) => {
                checkNetworkReachable();
                if (error?.response?.status === 401) {
                    logoutUser();
                } else if (!axios.isCancel(error) && error?.response) {
                    alert(DEFAULT_ERROR_MSG);
                }
            });
        dispatch({ type: LOADER, payload: false });
    };

    const getData = () => {
        if (state?.activeTab === TABLENAMES.notMergedTable) {
            getDataForNonMergedPayment()
        } else if (state?.activeTab === TABLENAMES.mergedTable) {
            getDataForMergedPayment()
        }
    }

    const getDataForNonMergedPayment = async () => {
        dispatch({ type: LOADER, payload: true });

        const handleSuccessResponse = (res) => {
            dispatch({
                type: UPDATE_MULTIPLE_DATA,
                payload: { tableData: res, loading: false },
            });
        };

        await api
            .post(
                GET_NOT_MERGED_PAYMENTS_URL,
                {
                    client_id: notMergedFilterState?.client_id[0],
                    reporting_year: notMergedFilterState?.reporting_year,
                    currency_id: notMergedFilterState?.currency_id[0],
                },
                {
                    cancelToken: cancelTokenSource.token,
                }
            )
            .then((res) => {
                handleSuccessResponse(res.data);
            })
            .catch((error) => {
                checkNetworkReachable();
                dispatch({ type: LOADER, payload: false });
                if (error?.response?.status === 401) {
                    logoutUser();
                } else if (!axios.isCancel(error) && error?.response) {
                    alert(DEFAULT_ERROR_MSG);
                }
            });
    };
    const getDataForMergedPayment = async () => {
        dispatch({ type: LOADER, payload: true });

        const handleSuccessResponse = (res) => {
            dispatch({
                type: UPDATE_MULTIPLE_DATA,
                payload: { tableData: res, loading: false },
            });
        };

        await api
            .post(
                GET_MERGED_PAYMENTS_URL,
                {
                    client_id: mergedFilterState?.client_id[0],
                    currency_id: mergedFilterState?.currency_id[0]
                },
                {
                    cancelToken: cancelTokenSource.token,
                }
            )
            .then((res) => {
                handleSuccessResponse(res.data);
            })
            .catch((error) => {
                checkNetworkReachable();
                dispatch({ type: LOADER, payload: false });
                if (error?.response?.status === 401) {
                    logoutUser();
                } else if (!axios.isCancel(error) && error?.response) {
                    alert(DEFAULT_ERROR_MSG);
                }
            });
    };

    const getInvoiceDetailsTableData = async () => {
        dispatch({ type: LOADER, payload: true });
        let URL = `${GET_INVOICE_DETAILS_TABLE_FOR_PAYMENT_URL}${state?.selectedRowData?.id}`

        let endpoints = [
            { url: URL, method: "post", payload: { merged: state?.activeTab === TABLENAMES.mergedTable } }
        ];

        await axios
            .all(
                endpoints.map((endpoint) => {
                    if (endpoint.method === "get") {
                        return api.get(endpoint.url, {
                            cancelToken: cancelTokenSource.token,
                        });
                    } else {
                        return api.post(endpoint.url, endpoint.payload, {
                            cancelToken: cancelTokenSource.token,
                        });
                    }
                })
            )
            .then(
                axios.spread(({ data: paymentInvoiceDetailTable }) => {
                    dispatch({
                        type: UPDATE_MULTIPLE_DATA,
                        payload: {
                            paymentInvoiceDetailTable,
                            loading: false,
                        },
                    });
                })
            )
            .catch((error) => {
                checkNetworkReachable();
                if (error?.response?.status === 401) {
                    logoutUser();
                } else if (!axios.isCancel(error) && error?.response) {
                    alert(DEFAULT_ERROR_MSG);
                }
            });
        dispatch({ type: LOADER, payload: false });
    }

    const getPaymentByID = async (id) => {
        dispatch({ type: LOADER, payload: true });
        dispatch({ type: SELECTED_ROW, payload: { id: id } })
    }

    let contextData = {
        state,
        dispatch,
        notMergedFilterState,
        notMergedFilterdispatch,
        mergedFilterState,
        mergedFilterdispatch,
        getData,
        getInvoiceDetailsTableData,
        pageName,
        getPaymentByID,
    };

    return (
        <PaymentContextAPI.Provider value={contextData}>
            {children}
        </PaymentContextAPI.Provider>
    );
}