import { LOADER, RESET_FILTERS, UPDATE_MULTIPLE_DATA, TOGGLE_SELECTION, CLEAR_SELECTION, REFRESH_TABLE } from "./actions";

export const initialState = {
    loading: false,
    refreshTable:false,
    tableData: [],
   
    clientOptions: [],
    yearOptions: [],
    currencyOptions: [],

    client_name: [],
    currency_code: [],

    client_id: [],
    reporting_year: "",
    currency_id: [],
    selectedItems: [], 
};

export const mergePaymentsDataReducer = (state, action) => {
    switch (action.type) {
        case LOADER:
            return { ...state, loading: action.payload };
        case UPDATE_MULTIPLE_DATA:
            return { ...state, ...action.payload };
        case RESET_FILTERS:
            return {
                ...state,
                client_name: [],
                year_name: [],
                currency_code: [],
                client_id: [],
                reporting_year: "",
                currency_id: [],
                tableData: [],
                selectedItems: [],
            };
        case TOGGLE_SELECTION:
            const { id } = action.payload;
            const isSelected = state.selectedItems.includes(id);
            return {
                ...state,
                selectedItems: isSelected
                    ? state.selectedItems.filter((itemId) => itemId !== id)
                    : [...state.selectedItems, id],
            };
        case CLEAR_SELECTION:
            return { ...state, selectedItems: [] };
        case REFRESH_TABLE:
            return { ...state, refreshTable: state.refreshTable + 1 };       
        default:
            return state;
    }
};
