import React, { useCallback, useState } from "react";
import { View, Text, FlatList, TouchableHighlight } from "react-native";
import { Menu, Searchbar, withTheme } from "react-native-paper";
import { formSelectCustomStyle, formSelectStyle } from "./formSelectStyle";
import { MaterialIcons } from "@expo/vector-icons";
import { FormLabel } from "..";
import { sortDynamic } from "../../../utils/sortDynamic";
import { SORTDIRECTION } from "../../CommonDataTable/commonDataTable.config";
import MessageValidation from "../../MessageValidation";
import { FORM_REQUIRED_FIELD_MSG } from "../../../constants/GlobalVariables";
import NoData from "../../NoData";

const FormSelect = ({
  name,
  label,
  options = [],
  onChange = () => { },
  searchable = false,
  disabled = false,
  theme,
  value = "",
  optionName = "title",
  optionValue = "value",
  required = false,
  placeholdertext = "",
  isValidated = false,
  validationMessage = FORM_REQUIRED_FIELD_MSG,
  showHelpText = true,
  sortValues = true,
  fixWidth = true,
  disableOptions = [],
}) => {
  const [visible, setVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [widthOfTheDropdown, setWidthOfTheDropdown] = useState(0);

  const openMenu = () => setVisible(true);

  const closeMenu = () => {
    setVisible(false);
    if (searchTerm) {
      setSearchTerm("");
    }
  };

  const onItemSelection = (option) => {
    if (option[optionValue] !== value) {
      onChange(name, option[optionValue], option[optionName], option);
    }
    closeMenu();
  };

  const getOptions = useCallback(() => {
    let filteredData = [...options];
    if (searchTerm !== "") {
      filteredData = [...options].filter(
        (element) =>
          element?.[optionName] &&
          element?.[optionName].toLocaleLowerCase().includes(searchTerm.trim().toLocaleLowerCase())
      );
    }
    if (sortValues) {
      let ascOptions = [...filteredData].sort(
        sortDynamic(optionName, SORTDIRECTION.ascending)
      );
      return ascOptions;
    }
    return filteredData;
  }, [searchTerm, value, JSON.stringify(options)]);

  const getValue = useCallback(() => {
    let label = "";
    if (value !== "" && value !== null && options.length > 0) {
      label = options.find((el) => el[optionValue] === value);
      if (label) {
        return label[optionName];
      }
    }
    return label;
  }, [value, options]);

  const onLayout = (event) => {
    const { width } = event.nativeEvent.layout;
    setWidthOfTheDropdown(width);
  };

  return (
    <View style={formSelectCustomStyle.container}>
      {!!label && (
        <FormLabel
          text={label}
          // customStyle={formSelectCustomStyle.customDropdownMenuLabel}
          required={required}
          isValidated={isValidated}
        />
      )}
      <Menu
        visible={visible}
        onDismiss={closeMenu}
        style={formSelectStyle(theme, disabled).customDropdownContainer}
        anchor={
          <>
            <TouchableHighlight
              onLayout={onLayout}
              underlayColor={theme.colors.selectedTableBackgroundColor}
              disabled={disabled}
              uppercase={false}
              onPress={openMenu}
              style={
                formSelectStyle(theme, disabled, isValidated).customDropdownBtn
              }
            >
              <View
                style={
                  fixWidth
                    ? formSelectStyle(theme, disabled)
                      .customDropdownFixWidthBtnContainer
                    : formSelectStyle(theme, disabled)
                      .customDropdownBtnContainer
                }
              >
                <View>
                  <Text
                    numberOfLines={1}
                    style={
                      formSelectStyle(theme, disabled).customDropdownBtnText
                    }
                  >
                    {value ? (
                      getValue()
                    ) : (
                      <Text
                        style={
                          formSelectStyle(theme, true).customDropdownBtnText
                        }
                      >
                        {placeholdertext}
                      </Text>
                    )}
                  </Text>
                </View>
                {!disabled && (
                  <MaterialIcons
                    name="keyboard-arrow-down"
                    size={24}
                    color={theme.colors.text}
                  />
                )}
              </View>
            </TouchableHighlight>
          </>
        }
      >
        {!!searchable && (
          <Searchbar
            onChangeText={(query) => {
              setSearchTerm(query);
            }}
            value={searchTerm}
          />
        )}
        <FlatList
          ListEmptyComponent={<NoData />}
          data={getOptions()}
          keyExtractor={(item, index) => index}
          renderItem={({ item }) => {
            let disableMenuItem = disableOptions.length > 0 && disableOptions.includes(item[optionName])
            return <Menu.Item
              disabled={disableMenuItem}
              style={formSelectStyle(theme).customDropdownMenuItem}
              onPress={() => {
                onItemSelection(item);
              }}
              title={item[optionName]}
              titleStyle={{
                minWidth: 600,
                color:
                  value === item[optionValue]
                    ? theme.colors.primary
                    : disableMenuItem
                      ? theme.colors.borderColor
                      : theme.colors.text,
                fontFamily:
                  value === item[optionValue]
                    ? theme.customFonts.semiBold
                    : theme.customFonts.regular,
              }}
            />
          }}
          style={{
            height: options.length > 5 ? 250 : null,
            width: widthOfTheDropdown,
          }}
        />
      </Menu>
      {showHelpText && (
        <MessageValidation
          fieldValidation={isValidated}
          message={validationMessage}
        />
      )}
    </View>
  );
};

export default withTheme(FormSelect);
