import { StyleSheet, Text, View } from 'react-native'
import React, { useContext } from 'react'
import { config, STATISTICS_DATA, TABLENAMES } from './payment.config'
import { commonStatisButtonCustomStyle, commonStatisticButtonStyle, CustomButton, Statistics, WebContentContainerWrapper } from '../../../components'
import PaymentContextAPI from './Reducer/PaymentsContextAPI'
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { BUTTON_TEXTS, BUTTON_TYPE, DEFAULT_ERROR_MSG } from '../../../constants/GlobalVariables'
import { customStyle } from '../../../components/CustomText/customStyle'
import { useTheme } from 'react-native-paper'
import MultiSelectFilter from '../Reports/CustomReportFilter/MultiSelectFilter'
import SingleSelectFilter from '../Reports/CustomReportFilter/SingleSelectFilter'
import GetDataFromFilters from './GetDataFromFilters'
import PaymentTable from './PaymentTable'
import { CHANGE_ACTIVE_TAB, LOADER, RESET_FILTERS, UPDATE_FILTER_DATA, UPDATE_MULTIPLE_DATA } from './Reducer/actions'
import { DOWNLOAD_MERGED_CSV_URL, DOWNLOAD_NOT_MERGED_CSV_URL } from '../../../constants/GlobalUrls'
import useAxios from '../../../hooks/useAxios'
import AuthContextAPI from '../../../context/AuthContextAPI'
import axios from 'axios'
import { checkNetworkReachable } from '../../../utils/checkNetworkReachable'
import FormRadioButton from '../../../components/FormControl/FormRadio/FormRadioButton'
import { accountEditTableStyle } from '../Accounts/AccountEditPage/AccountEditTables'
import FormRadioGroup from '../../../components/FormControl/FormRadio/FormRadioGroup'
let uuid = require("uuid");

const LandingPage = () => {
    let theme = useTheme();

    // api hook
    let api = useAxios();

    const { logoutUser } = useContext(AuthContextAPI);

    // cancel token used to cancel the call if component is unmounted or need to programmatically cancel the request
    const cancelTokenSource = axios.CancelToken.source();

    let {
        state: {
            loading,
            tableData,
            activeTab,
        },
        notMergedFilterState,
        notMergedFilterdispatch,
        mergedFilterState,
        mergedFilterdispatch,
        dispatch,
    } = useContext(PaymentContextAPI);

    const onChangeYear = (name, value) => {
        let payload = {
            [name]: value,
        };

        if (activeTab === TABLENAMES.notMergedTable) {
            notMergedFilterdispatch({ type: UPDATE_FILTER_DATA, payload: payload });
        }
        dispatch({
            type: UPDATE_MULTIPLE_DATA,
            payload: { tableData: [] },
        });
    };

    const onChangeMultiSelectFilter = (value) => {
        let tempValue = { ...value };

        if (activeTab === TABLENAMES.notMergedTable) {
            notMergedFilterdispatch({
                type: UPDATE_FILTER_DATA,
                payload: tempValue,
            });
        } else if (activeTab === TABLENAMES.mergedTable) {
            mergedFilterdispatch({
                type: UPDATE_FILTER_DATA,
                payload: tempValue,
            });
        }
        dispatch({
            type: UPDATE_MULTIPLE_DATA,
            payload: { tableData: [] },
        });
    };

    const onClearAllFilter = () => {
        dispatch({
            type: RESET_FILTERS,
            payload: {},
        });
        if (activeTab === TABLENAMES.notMergedTable) {
            notMergedFilterdispatch({
                type: RESET_FILTERS,
                payload: {},
            });
        } else if (activeTab === TABLENAMES.mergedTable) {
            mergedFilterdispatch({
                type: RESET_FILTERS,
                payload: {},
            });
        }

    };

    const onTabChange = (tab) => {
        dispatch({ type: CHANGE_ACTIVE_TAB, payload: tab });
        onClearAllFilter();
    };


    const onClickDownloadCSV = async (isMerged) => {
        dispatch({ type: LOADER, payload: true });

        let response = null

        if (isMerged === TABLENAMES.mergedTable) {
            response = api
                .get(DOWNLOAD_MERGED_CSV_URL, {
                    responseType: "blob",
                    cancelToken: cancelTokenSource.token,
                })
        } else {
            response = api
                .post(DOWNLOAD_NOT_MERGED_CSV_URL, {
                    responseType: "blob",
                    cancelToken: cancelTokenSource.token,
                })
        }
        if (response) {
            await response.then((response) => {
                //Create a Blob from the csv Stream
                const file = new Blob([response.data], { type: "text/csv" });
                //Build a URL from the file
                var csvURL = window.URL.createObjectURL(file);
                let tempLink = document.createElement("a");
                tempLink.href = csvURL;
                tempLink.setAttribute("download", `${isMerged === TABLENAMES.mergedTable ? "merged" : "not_merged"}_${uuid.v4()}.csv`);
                tempLink.click();
                dispatch({ type: LOADER, payload: false });
            })
                .catch((error) => {
                    checkNetworkReachable();
                    dispatch({ type: LOADER, payload: false });
                    if (error?.response?.status === 401) {
                        logoutUser();
                    } else if (!axios.isCancel(error) && error?.response) {
                        alert(DEFAULT_ERROR_MSG);
                    }
                });
        }

    };

    const extraHeaderComponent = (
        <View style={commonStatisticButtonStyle(theme).statisticContainer}>
            <Statistics label={STATISTICS_DATA.TOTAL} value={tableData.length || 0} />
            <Statistics
                label={STATISTICS_DATA.PAID}
                value={
                    tableData.filter((invoice) => invoice.status === STATISTICS_DATA.PAID)
                        .length
                }
            />
            <Statistics
                label={STATISTICS_DATA.REQUESTED}
                value={
                    tableData.filter((invoice) => invoice.status === STATISTICS_DATA.REQUESTED)
                        .length
                }
            />
            <View style={commonStatisButtonCustomStyle(theme).buttonContainer}>
                <CustomButton
                    text={BUTTON_TEXTS.DOWNLOAD_NOT_MERGED_CSV}
                    cutomButtonStyle={commonStatisButtonCustomStyle(theme).downloadBtn}
                    textStyle={commonStatisButtonCustomStyle(theme).text}
                    onPress={() => { onClickDownloadCSV(TABLENAMES.notMergedTable) }}
                />
                <CustomButton
                    text={BUTTON_TEXTS.DOWNLOAD_MERGED_CSV}
                    cutomButtonStyle={commonStatisButtonCustomStyle(theme).downloadBtn}
                    textStyle={commonStatisButtonCustomStyle(theme).text}
                    onPress={() => { onClickDownloadCSV(TABLENAMES.mergedTable) }}
                />
            </View>
        </View>
    );

    let selectActiveTab = (
        <FormRadioGroup
            inlineLabel
            label={"Table:"}
            value={activeTab}
            onChange={(name, value) => {
                onTabChange(value);
            }}
        >
            <View style={accountEditTableStyle.radioButtonWrapper}>
                <FormRadioButton
                    label={`${TABLENAMES.notMergedTable}`}
                    value={TABLENAMES.notMergedTable}
                />
                <FormRadioButton
                    label={`${TABLENAMES.mergedTable}`}
                    value={TABLENAMES.mergedTable}
                />
            </View>
        </FormRadioGroup>
    );

    return (
        <WebContentContainerWrapper loading={loading} title={config.TITLE}>
            <View style={styles.headerContainer}>
                <Text style={customStyle(theme).headerText20}>
                    Make your selection
                </Text>
                <CustomButton
                    buttontype={BUTTON_TYPE.SECONDARY}
                    onPress={onClearAllFilter}
                    text={
                        <View style={styles.clearBtnTextContainer}>
                            <MaterialCommunityIcons
                                name="restart"
                                size={20}
                                color={theme.colors.black}
                            />
                            <Text>Restart</Text>
                        </View>
                    }
                />
            </View>
            <View style={styles.filtersContainer}>
                <MultiSelectFilter
                    showEraser
                    optionName="client_name"
                    optionValue="client_id"
                    multipleSelect={false}
                    name={config.CLIENT_KEY}
                    title={config.CLIENT_NAME}
                    placeholdertext={config.CLIENTS_TITLE}
                    value={
                        (activeTab === TABLENAMES.notMergedTable)
                            ? notMergedFilterState?.client_id
                            : mergedFilterState?.client_id
                    }
                    options={
                        (activeTab === TABLENAMES.notMergedTable)
                            ? notMergedFilterState?.clientOptions
                            : mergedFilterState?.clientOptions
                    }
                    onChange={onChangeMultiSelectFilter}
                />

                <SingleSelectFilter
                    showEraser
                    disabled={activeTab === TABLENAMES.mergedTable}
                    placeholdertext={config.YEAR_TITLE}
                    name={config.YEAR_NAME}
                    value={
                        (activeTab === TABLENAMES.notMergedTable)
                            ? notMergedFilterState?.reporting_year
                            : ""
                    }
                    options={
                        (activeTab === TABLENAMES.notMergedTable)
                            ? notMergedFilterState?.yearOptions
                            : []
                    }
                    onChange={onChangeYear}
                />
                <MultiSelectFilter
                    showEraser
                    optionName={config.CURRENCY_NAME}
                    optionValue="currency_id"
                    multipleSelect={false}
                    name={config.CURRENCY_KEY}
                    title={config.CURRENCY_NAME}
                    placeholdertext={config.CURRENCY_TITLE}
                    value={
                        (activeTab === TABLENAMES.notMergedTable)
                            ? notMergedFilterState?.currency_id
                            : mergedFilterState?.currency_id
                    }
                    options={
                        (activeTab === TABLENAMES.notMergedTable)
                            ? notMergedFilterState?.currencyOptions
                            : mergedFilterState?.currencyOptions
                    }
                    onChange={onChangeMultiSelectFilter}
                />
            </View>
            <GetDataFromFilters />
            <View style={styles.statisticContainer}>
                {extraHeaderComponent}
            </View>
            <PaymentTable
                activeTab={activeTab}
                selectActiveTab={selectActiveTab}
            />
        </WebContentContainerWrapper>
    )
}

export default LandingPage

const styles = StyleSheet.create({
    filtersContainer: {
        flexDirection: "row",
        gap: 10,
        flex: 1,
        justifyContent: "space-between",
    },
    headerContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    clearBtnTextContainer: { flexDirection: "row", gap: 5, alignItems: "center" },
    statisticContainer: { marginTop: 15 },
});
