import { View, StyleSheet  } from "react-native";
import React, { useState, useContext } from "react";
import * as DocumentPicker from "expo-document-picker";
import { withTheme, List, Text } from "react-native-paper";
import {
  ActionsButtonIcons,
  commonFormStyle,
  commonStatisButtonCustomStyle,
  CustomButton,
  CustomProgressBar,
  Loader,
} from "../../../../components";
import {
  ACTION_BUTTON_TYPE,
  BUTTON_TEXTS,
  DEFAULT_ERROR_MSG,
} from "../../../../constants/GlobalVariables";
import useAxios from '../../../../hooks/useAxios';
import axios from "axios";
import AuthContextAPI from '../../../../context/AuthContextAPI';
import { checkNetworkReachable } from '../../../../utils/checkNetworkReachable';
import { POST_CSV_URL } from '../../../../constants/GlobalUrls';


const UploadCSVForm = ({ theme, cancelButton, successAddCSV }) => {
  let api = useAxios();
  const { logoutUser } = useContext(AuthContextAPI);

  const cancelTokenSource = axios.CancelToken.source();

  const [isLoading, setIsLoading] = useState(false);
  const [fileUploadProgress, setFileUploadProgress] = useState(0);
  const [disableForm, setDisableForm] = useState(false);

  const [uploadFormData, setUploadFormData] = useState({
    file: [],
  });

  const onChange = (name, value) => {
      setUploadFormData({ ...uploadFormData, [name]: value });
  };

  const _pickDocument = async () => {
    let result = await DocumentPicker.getDocumentAsync({
      multiple: true,
      type: "text/csv",
    });
    if (result.type === "success") {
      const files = uploadFormData.file ? [...uploadFormData.file] : [];
      files.push(result);
      onChange("file", files);
    }
  };

  const onSubmit = async () => {
    let allFiles = [...uploadFormData.file];

    setDisableForm(true);

  let data = new FormData();
   allFiles.forEach((file) => {
    data.append("file", file.file);
  });

  const config = {
    "content-type": "multipart/form-data",
    cancelToken: cancelTokenSource.token,
    onUploadProgress: (progressEvent) => {
      let percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      if (percentCompleted === 100) {
        setIsLoading(true);
      }
      setFileUploadProgress(percentCompleted);
    },
  };

    const handleSuccessResponse = (res) => {
    if (res?.msg) {
        alert(res.msg);
    }
    successAddCSV();
    };

    const handleErrorResponse = (error) => {
    checkNetworkReachable();
    if (error?.response?.status === 401) {
        logoutUser();
    } else if (!axios.isCancel(error) && error?.response) {
        setDisableForm(false);
        setFileUploadProgress(0)
        alert(error?.response?.data?.msg || DEFAULT_ERROR_MSG);
    }
    };

    await api
    .post(POST_CSV_URL, data, config)
    .then((res) => {
        handleSuccessResponse(res.data);
    })
    .catch((error) => {
        handleErrorResponse(error);
        setIsLoading(false);
    });
  };

  const deleteFile = (index) => {
    var allFiles = [...uploadFormData.file];
    allFiles.splice(index, 1);
    onChange("file", allFiles);
  };

  const getFileList = Array.from(uploadFormData.file).map((item, i) => (
    <List.Item
      key={i}
      title={item.name}
      right={(props) =>
        !disableForm && (
          <ActionsButtonIcons
            buttonType={ACTION_BUTTON_TYPE.DELETE}
            handleOnPress={() => {
              deleteFile(i);
            }}
          />
        )
      }
    />
  ));

  return (
    <View style={[commonFormStyle(theme).modalFormContainer]}>
      {fileUploadProgress === 100 && (
        <Text style={commonFormStyle(theme).uploadMsgText}>
          Uploading to server...
        </Text>
      )}
      {!disableForm ? (
        <View>
          <Loader loading={isLoading} />
          <View style={commonFormStyle(theme).tableFormFieldContainer}>
            <CustomButton
              text={"Select File(s)"}
              cutomButtonStyle={commonStatisButtonCustomStyle(theme).btn}
              textStyle={commonStatisButtonCustomStyle(theme).text}
              onPress={_pickDocument}
            />
          </View>
          <View style={fileNote.container}>
          <Text style={fileNote.asterisk}>*</Text>
          <Text style={fileNote.message}>
            Only Tipalti, Sparkasse, Credit Suisse payment data in .csv format are supported
          </Text>
          </View>
          <View style={fileNote.contentContainer}>
          <View style={[commonFormStyle(theme).tableFormFieldContainer,fileNote.fileContainer]}>
          {Array.from(uploadFormData.file).length > 0 && (
           <>
            {getFileList}
           </>
          )}
            </View>
          </View>
          <View style={commonFormStyle(theme).actionBtnContainer}>
            <CustomButton
              disabled={uploadFormData.file.length === 0}
              onPress={onSubmit}
              text={BUTTON_TEXTS.SUBMIT}
              cutomButtonStyle={commonFormStyle(theme).submitBtn}
            />
            <CustomButton
              text={BUTTON_TEXTS.CANCEL}
              onPress={cancelButton}
              cutomButtonStyle={commonFormStyle(theme).backBtn}
            />
          </View>
        </View>
      ) : (
        <View>
          <Loader loading={isLoading} />
          {getFileList}
          <CustomProgressBar progress={fileUploadProgress} />
        </View>
      )}
    </View>
  );
};



const fileNote = StyleSheet.create({
    container: {
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'row',
    },
    contentContainer: {
      minHeight: 120,
      },
    fileContainer: {
      height: 150, 
      overflow: 'scroll'
    },
    asterisk: {
      color: '#FF0000',
      fontSize: 15,
      fontStyle: 'italic',
      fontWeight: 'bold',
      paddingTop: 7,
    },
    message: {
      fontSize: 15,
      fontStyle: 'italic',
      fontWeight: 'bold',
      paddingTop: 7,
    },
  });

export default withTheme(UploadCSVForm);
