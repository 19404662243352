import axios from "axios";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { View } from "react-native";
import { withTheme } from "react-native-paper";
import useAxios from "../../../../hooks/useAxios";
import { commonFormStyle, CustomButton, Loader } from "../../../../components";
import {
  FormControlInput,
  FormDatePicker,
  FormSelect,
} from "../../../../components/FormControl";
import MessageValidation from "../../../../components/MessageValidation";
import { CURRENCY_URL } from "../../../../constants/GlobalUrls";
import {
  BUTTON_TEXTS,
  DEFAULT_ERROR_MSG,
  DEFAULT_FIELD_VALIDATION_MESSAGE,
  MESSAGE_TYPE,
} from "../../../../constants/GlobalVariables";
import { checkObjectIsEmpty } from "../../../../utils/checkObjectIsEmpty";
import { ADVANCES_TYPES, TABLE_ACTION } from "./ContractManagerDealForm.config";
import AuthContextAPI from "../../../../context/AuthContextAPI";
import { checkNetworkReachable } from "../../../../utils/checkNetworkReachable";
let uuid = require("uuid");

const AdvancesForm = ({
  data,
  cancelButton,
  getAdvanceData,
  advanceFor = ADVANCES_TYPES.CLIENT,
  theme,
}) => {
  let isEditMode = Object.keys(data).length > 0;
  // api hook
  let api = useAxios();
  const { logoutUser } = useContext(AuthContextAPI);
  // cancel token used to cancel the call if component is unmounted or need to programmatically cancel the request
  const cancelTokenSource = axios.CancelToken.source();
  const [currenyOptions, setCurrenyOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState({
    show: false,
    type: "",
    message: "",
  });

  const [formData, setFormData] = useState({
    key: uuid.v4(),
    id: "New",
    type: advanceFor,
    rawDate: "",
    advancedate: "",
    amount: 0,
    currencyid: "",
    action: TABLE_ACTION.add,
  });

  const { id, type, advancedate, amount, currencyid, rawDate } = formData;

  useEffect(() => {
    let isMoulded = true;
    if (isMoulded) {
      if (currenyOptions.length === 0) {
        getCurrencyOptions();
      }
      if (isEditMode) {
        let tempData = data;
        let d = data.advancedate && data.advancedate.split("/");
        let dat = new Date(d[2] + "/" + d[1] + "/" + d[0]);
        tempData = {
          ...tempData,
          rawDate: dat,
        };
        setFormData(tempData);
      }
    }
    return () => {
      isMoulded = false;
      cancelTokenSource.cancel();
    };
  }, [data]);

  const getCurrencyOptions = async () => {
    setIsLoading(true);
    const handleSuccessResponse = (res) => {
      setCurrenyOptions(res);
    };

    await api
      .get(CURRENCY_URL, {
        cancelToken: cancelTokenSource.token,
      })
      .then((res) => {
        handleSuccessResponse(res.data);
      })
      .catch((error) => {
        checkNetworkReachable();
        if (error?.response?.status === 401) {
          logoutUser();
        } else if (!axios.isCancel(error) && error?.response) {
          alert(DEFAULT_ERROR_MSG);
        }
      });
    setIsLoading(false);
  };

  const resetMessageObj = () => {
    setMessage({
      show: false,
      type: "",
      message: "",
    });
  };

  const onChange = (name, value, selectedName) => {
    if (message.show) {
      resetMessageObj();
    }
    if (name === "type") {
      resetMessageObj();
      setFormData({ ...formData, [name]: value });
    } else if (name === "rawDate") {
      setFormData({
        ...formData,
        [name]: value,
        advancedate: dayjs(value).format("DD/MM/YYYY"),
      });
    } else if (name === "currencyid") {
      setFormData({
        ...formData,
        [name]: value,
        currencycode: selectedName,
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const onSubmit = async () => {
    let requiredFields = { id, type, advancedate, amount, currencyid };
    let isEmpty = checkObjectIsEmpty(requiredFields);
    if (isEmpty) {
      let submitFormData = formData;
      if (id !== "New") {
        submitFormData = { ...formData, action: TABLE_ACTION.update };
      }
      getAdvanceData(submitFormData);
    } else {
      setIsLoading(false);
      setMessage({
        show: true,
        type: MESSAGE_TYPE.error,
        message: DEFAULT_FIELD_VALIDATION_MESSAGE,
      });
    }
  };

  return (
    <View style={commonFormStyle(theme).modalFormContainer}>
      <Loader loading={isLoading} />
      <FormControlInput
        label="Advance Category"
        name={"type"}
        value={type}
        onChange={onChange}
        disabled={true}
      />
      {formData.clientid && (
        <FormControlInput
          label="Category ID"
          name={"clientid"}
          value={formData.clientid}
          // onChange={onChange}
          disabled={true}
        />
      )}
      {!!type && (
        <>
          <FormDatePicker
            required
            label="Advance Date"
            name={"rawDate"}
            value={rawDate}
            onChange={onChange}
            isValidated={message.show && !rawDate}
          />
          <FormControlInput
            required
            label="Amount"
            isNumeric
            name={"amount"}
            value={amount}
            onChange={onChange}
            isValidated={message.show && !(amount || amount === 0)}
          />
          <FormSelect
            required
            label={"Select Currency"}
            options={currenyOptions}
            onChange={onChange}
            value={currencyid}
            name={"currencyid"}
            optionName="currencycode"
            optionValue="id"
            isValidated={message.show && !currencyid}
          />
        </>
      )}
      <MessageValidation {...message} />
      {!!!isLoading && (
        <View style={commonFormStyle(theme).actionBtnContainer}>
          <CustomButton
            onPress={onSubmit}
            text={BUTTON_TEXTS.SUBMIT}
            cutomButtonStyle={commonFormStyle(theme).submitBtn}
          />
          <CustomButton
            text={BUTTON_TEXTS.CANCEL}
            onPress={cancelButton}
            cutomButtonStyle={commonFormStyle(theme).backBtn}
          />
        </View>
      )}
    </View>
  );
};

export default withTheme(AdvancesForm);
