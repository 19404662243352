import React, { useContext } from "react";
import AllFilters from "./CustomReportFilter/AllFilters";
import { WebContentContainerWrapper } from "../../../components";
import { config } from "./report.config";
import ReportsContextAPI from "./ReportsContextAPI";
import GeneratedReportTable from "./GeneratedReportTable";

const ReportsLandingPage = () => {
  let {
    state: { loading, tableLoader },
  } = useContext(ReportsContextAPI);
  return (
    <WebContentContainerWrapper
      title={config.TITLE}
      loading={loading || tableLoader}
    >
      <AllFilters />
      <GeneratedReportTable />
    </WebContentContainerWrapper>
  );
};

export default ReportsLandingPage;
