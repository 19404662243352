import { createContext, useCallback, useContext, useEffect, useReducer } from "react";
import AuthContextAPI from "../../../../context/AuthContextAPI";
import axios from "axios";
import { initialState, ImportPaymentDataReducer } from "./ImportPaymentReducer";
import useAxios from "../../../../hooks/useAxios";
import { checkNetworkReachable } from "../../../../utils/checkNetworkReachable";
import { DEFAULT_ERROR_MSG } from "../../../../constants/GlobalVariables";
import { LOADER, UPDATE_MULTIPLE_DATA } from "./actions";
import { GET_ALL_CURRENCY_IMPORTED_PAYMENTS_URL, GET_CLIENTS_FILTER_IMPORTED_PAYMENTS_URL, GET_YEARS_FILTER_IMPORTED_PAYMENTS_URL,IMPORTED_PAYMENTS_URL } from "../../../../constants/GlobalUrls";

const ImportPaymentsContextAPI = createContext();

export default ImportPaymentsContextAPI;

export const ImportPaymentProvider = ({ children }) => {

    // api hook
    let api = useAxios();

    const { logoutUser } = useContext(AuthContextAPI);

    // cancel token used to cancel the call if component is unmounted or need to programmatically cancel the request
    const cancelTokenSource = axios.CancelToken.source();

    const [state, dispatch] = useReducer(ImportPaymentDataReducer, initialState);

    useEffect(() => {
        let isMoulded = true;
        if (isMoulded) {
            getAllOptions();
        }
        return () => {
            isMoulded = false;
            cancelTokenSource.cancel();
        };
    }, []);

    useEffect(() => {
        let isMoulded = true;
        if (isMoulded && state.refreshTable > 0) {
            getData();
        }
        return () => {
            isMoulded = false;
            cancelTokenSource.cancel();
        };
    }, [state.refreshTable]);

    const getAllOptions = async () => {
        dispatch({ type: LOADER, payload: true });
        let endpoints = [
            { url: GET_CLIENTS_FILTER_IMPORTED_PAYMENTS_URL, method: "get" },
            { url: GET_YEARS_FILTER_IMPORTED_PAYMENTS_URL, method: "get" },
            { url: GET_ALL_CURRENCY_IMPORTED_PAYMENTS_URL, method: "get" },
        ];
        await axios
            .all(
                endpoints.map((endpoint) => {
                    if (endpoint.method === "get") {
                        return api.get(endpoint.url, {
                            cancelToken: cancelTokenSource.token,
                        });
                    } else {
                        return api.post(endpoint.url, {
                            cancelToken: cancelTokenSource.token,
                        });
                    }
                })
            )
            .then(
                axios.spread(
                    ({ data: clientOptions }, { data: yearOptions }, { data: currencyOptions }) => {
                        dispatch({
                            type: UPDATE_MULTIPLE_DATA,
                            payload: {
                                clientOptions,
                                yearOptions,
                                currencyOptions,
                                loading: false,
                            },
                        });
                    }
                )
            )
            .catch((error) => {
                checkNetworkReachable();
                if (error?.response?.status === 401) {
                    logoutUser();
                } else if (!axios.isCancel(error) && error?.response) {
                    alert(DEFAULT_ERROR_MSG);
                }
            });
        dispatch({ type: LOADER, payload: false });
    };

    const getData = async () => {
        dispatch({ type: LOADER, payload: true });

        const handleSuccessResponse = (res) => {
            dispatch({
                type: UPDATE_MULTIPLE_DATA,
                payload: { tableData: res, loading: false },
            });
        };

        await api
            .post(
                IMPORTED_PAYMENTS_URL,
                {
                    client_id: state?.client_id[0],
                    reporting_year: state?.reporting_year,
                    currency_id: state?.currency_id[0]
                },
                {
                    cancelToken: cancelTokenSource.token,
                }
            )
            .then((res) => {
                handleSuccessResponse(res.data);
            })
            .catch((error) => {
                checkNetworkReachable();
                dispatch({ type: LOADER, payload: false });
                if (error?.response?.status === 401) {
                    logoutUser();
                } else if (!axios.isCancel(error) && error?.response) {
                    alert(DEFAULT_ERROR_MSG);
                }
            });
    };

    let contextData = {
        state,
        dispatch,
        getData,
    };

    return (
        <ImportPaymentsContextAPI.Provider value={contextData}>
          {children}
        </ImportPaymentsContextAPI.Provider>
    );
}