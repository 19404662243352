import React from "react";
import { Platform, Text, TouchableOpacity, View } from "react-native";
import { withTheme } from "react-native-paper";
import { BUTTON_TYPE } from "../../constants/GlobalVariables";

const CustomButton = ({
  text,
  icon,
  buttontype = BUTTON_TYPE.PRIMARY,
  paddingHorizontal=50,
  onPress,
  cutomButtonStyle = {},
  textStyle = {},
  disabled = false,
  theme,
}) => {
  // button type = primary, secondary, link
  const { customFonts, colors } = theme;
  let height = Platform.OS === "web" ? 56 : 44;

  let buttonColor =
    buttontype === BUTTON_TYPE.PRIMARY ? colors.primary : colors.background;

  let borderColor =
    buttontype === BUTTON_TYPE.LINK ? colors.contrastText : colors.primary;

  let textColor =
    buttontype === BUTTON_TYPE.SECONDARY ? colors.white : colors.black;

  const textStyles =
    buttontype === BUTTON_TYPE.LINK
      ? {
          textDecorationLine: "underline",
          fontFamily: customFonts.semiBold,
          fontSize: 16,
          color: theme.dark ? colors.primary : colors.black,
          ...textStyle,
        }
      : {
          fontFamily: customFonts.semiBold,
          fontSize: 16,
          color: theme.dark ? textColor : colors.black,
          ...textStyle,
        };

  return (
    <TouchableOpacity
      style={{
        borderColor: borderColor,
        borderWidth: 1,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        height: height,
        backgroundColor: buttonColor,
        borderRadius: 10,
        paddingHorizontal: paddingHorizontal,
        ...cutomButtonStyle,
        opacity: disabled? 0.5: 1
      }}
      onPress={onPress}
      disabled={disabled}
    >
      {icon && <View style={{ marginRight: 8 }}>{icon}</View>}
      <Text style={textStyles}>{text}</Text>
    </TouchableOpacity>
  );
};

export default withTheme(CustomButton);
