import React, { useContext, useState, useMemo } from 'react';
import { StyleSheet, View, Text, Dimensions } from 'react-native';
import { useTheme } from 'react-native-paper';
import PaymentContextAPI from './Reducer/PaymentsContextAPI';
import { ACTION_BUTTON_TYPE } from '../../../constants/GlobalVariables';
import { breadCrumbStyle } from '../../../components/BreadCrumb/breadCrumbStyle';
import { ActionsButtonIcons, CommonDataTable, commonStatisButtonCustomStyle, CustomButton, CustomModal } from '../../../components';
import AddInvoiceToPaymentPopUp from './AddInvoiceToPaymentPopUp';
import InvoicesDataContextAPI from '../InvoicesData/InvoicesDataContextAPI';
import { config } from './payment.config';
import { formatCurrency } from '../../../utils/formatCurrency';

const screen = Dimensions.get("window");
const { width } = screen;

const InvoiceDetailForPaymentTable = ({ pageName }) => {
    let theme = useTheme();
    let {
        state: { paymentInvoiceDetailTable, selectedRowData, activeTab },
        getInvoiceDetailsTableData,
    } = useContext(PaymentContextAPI);

    let {
        getInvoiceByID,
    } = useContext(InvoicesDataContextAPI);

    const [showInvoices, setShowInvoices] = useState(false);

    const [tableData, setTableData] = useState({ linked_invoices_ids: [], data: [] });

    const [selectedRows, setSelectedRows] = useState([]);

    const onClickCloseShowInvoice = () => {
        setShowInvoices(false)
        setSelectedRows([])
        setTableData({ linked_invoices_ids: [], data: [] })
    }

    const onSubmitSuccessful = () => {
        getInvoiceDetailsTableData();
        onClickCloseShowInvoice();
    }

    let columns = [
        {
            key: "invoiceid",
            title: "ID",
            dataIndex: "invoiceid",
            sort: true,
            search: true,
            width: 1.5,
            // bold: true,
        },
        {
            key: "clientname",
            title: "Client Name",
            dataIndex: "clientname",
            sort: true,
            search: true,
        },
        {
            key: "service",
            title: "Service",
            dataIndex: "service",
            sort: true,
            filter: true,
        },

        {
            key: "fromfield",
            title: "From",
            dataIndex: "fromfield",
            sort: true,
            filter: true,
            width: 1.5,
        },
        {
            key: "to",
            title: "To",
            dataIndex: "to",
            sort: true,
            filter: true,
            width: 1.5,
        },
        {
            key: "currency",
            title: "Currency",
            dataIndex: "currency",
            sort: true,
            filter: true,
            width: 2,
        },
        {
            key: "gross",
            title: "Gross",
            dataIndex: "gross",
            sort: true,
            total: true,
            render: (currency) => {
                return formatCurrency(currency);
            },
        },
        {
            key: "net",
            title: "Net",
            dataIndex: "net",
            sort: true,
            total: true,
            width: 2.5,
            render: (currency) => {
                return formatCurrency(currency);
            },
        },
        {
            key: "adj",
            title: "Adj",
            dataIndex: "adj",
            sort: true,
            width: 2.5,
            render: (currency) => {
                return formatCurrency(currency);
            },
        },
        {
            key: "total",
            title: "Total",
            dataIndex: "total",
            sort: true,
            width: 2.5,
            render: (currency) => {
                return formatCurrency(currency);
            },
        },
        {
            key: "paymentdifference",
            title: "Payment Difference",
            dataIndex: "paymentdifference",
            sort: true,
            width: 3.5,
            render: (currency) => {
                return formatCurrency(currency);
            },
        },
        {
            key: "type",
            title: "Type",
            dataIndex: "type",
            sort: true,
            filter: true,
            width: 1.5,
        },
        {
            key: "reportversion",
            title: "Report Version",
            dataIndex: "reportversion",
            sort: true,
            filter: true,
            width: 2.5,
        }
    ];

    const paymentColumns = useMemo(() => [
        ...columns,
        {
            key: "invoiceid",
            title: "View",
            dataIndex: "invoiceid",
            center: true,
            width: 1,
            customToolTip: () => "View invoice detail",
            render: (id, record) => (
                <ActionsButtonIcons
                    buttonType={ACTION_BUTTON_TYPE.FILE_SEARCH}
                    handleOnPress={() => getInvoiceByID(id)}
                />
            ),
        }
    ], [columns, getInvoiceByID]);

    const relatedInvoicesIDs = useMemo(() => {
        return (paymentInvoiceDetailTable?.related_invoices ?? []).map(({ invoiceid }) => invoiceid);
    }, [paymentInvoiceDetailTable?.related_invoices]);

    return (
        <View style={styles(theme).container}>
            <View style={styles(theme).titleButtonWrapper}>
                <Text style={breadCrumbStyle(theme).titleLink}>{`Related Invoices (${paymentInvoiceDetailTable?.related_invoices.length})`}</Text>
                <CustomButton
                    text={"Add Invoice To Payment"}
                    cutomButtonStyle={commonStatisButtonCustomStyle(theme).btn}
                    textStyle={commonStatisButtonCustomStyle(theme).text}
                    onPress={() => { setShowInvoices(true) }}
                />
            </View>
            <CommonDataTable
                canFilterColumns
                showFooterTotal
                showPagination={false}
                columns={pageName === config.TITLE ? paymentColumns : columns}
                data={paymentInvoiceDetailTable?.related_invoices}
            />
            <CustomModal
                title={`Add Invoice to Payment`}
                visible={showInvoices}
                hideModal={onClickCloseShowInvoice}
            >
                <View style={styles(theme).modelContainer}>
                    <AddInvoiceToPaymentPopUp
                        selectedRowData={selectedRowData}
                        onSubmitSuccessful={onSubmitSuccessful}
                        onClickCloseShowInvoice={onClickCloseShowInvoice}
                        tableData={tableData}
                        selectedRows={selectedRows}
                        setTableData={setTableData}
                        setSelectedRows={setSelectedRows}
                        activeTab={activeTab}
                        relatedInvoicesIDs={relatedInvoicesIDs}
                        relatedPayments={paymentInvoiceDetailTable?.related_payments}
                    />
                </View>
            </CustomModal>
        </View>
    );
}

const styles = (theme) =>
    StyleSheet.create({
        container: { marginTop: 20 },
        titleButtonWrapper: { flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginBottom: 15 },
        modelContainer: { width: width - 150 },
    });


export default InvoiceDetailForPaymentTable;
